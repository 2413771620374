import React, { ReactElement, Suspense, useLayoutEffect } from 'react'
const RuleOrFolderDialog = React.lazy(() => import('./RuleOrFolderDialog'))
const CopyRuleDialog = React.lazy(() => import('./CopyRuleDialog'))
const MoveRuleDialog = React.lazy(() => import('./MoveRuleDialog'))
import useQueryString from 'utils/useQueryString'
import omit from 'lodash/omit'

export enum DialogType {
  RULE_OR_FOLDER = 'rule-or-folder',
  COPY = 'copy',
  MOVE = 'move',
}

export default function RuleOrFolderMainDialog(): ReactElement | null {
  const { nav, qs } = useQueryString()

  useLayoutEffect(() => {
    if (qs.ruleDialog) {
      nav({ ...omit(qs, 'ruleDialog') })
    }

    // trigger this effect only on the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!qs.ruleDialog) {
    return null
  }

  const Component =
    qs.ruleDialog === DialogType.COPY
      ? CopyRuleDialog
      : qs.ruleDialog === DialogType.MOVE
      ? MoveRuleDialog
      : RuleOrFolderDialog

  return (
    <Suspense>
      <Component isOpen={!!qs.ruleDialog} dismiss={() => nav({ ...omit(qs, 'ruleDialog') })} />
    </Suspense>
  )
}
