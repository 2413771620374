import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Button, IconButtonContent, SimpleActionMenuItemProps, Svg } from 'ui'
import { Flex, Text } from 'theme-ui'
import useOnClickOutside from 'utils/useOnClickOutside'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  AllActionType,
  setGroupForDeletion,
  setIsCustomRule,
  setRuleActionFilter,
  setShouldShowLargeView,
  setShouldSortRules,
  updateGroupDraft,
} from 'store/customRules'
import { batch } from 'react-redux'
import { downloadFile } from 'utils/downloadFile'
import CheckIcon from 'images/analytics/check.svg'
import DeleteIcon from 'images/menus/delete-icon.svg'
import EditIcon from 'images/menus/edit-icon.svg'
import ExportIcon from 'images/menus/external-link-icon.svg'
import SmallListIcon from 'images/small-list.svg'
import LargeListIcon from 'images/large-list.svg'
import CancelDeleteIcon from 'images/confirmDeletion/cancel-delete-icon.svg'
import ConfirmDeleteIcon from 'images/confirmDeletion/confirm-delete-icon.svg'
import MoreButton from 'ui/MoreButton'
import SortIcon from 'images/dashboard/sort-icon.svg'
import CheckmarkIcon from 'images/checkmark.svg'
import LastUpdatedIcon from 'images/dashboard/last-updated.svg'
import ActionsMenu from 'components/Dashboard/Profiles/CustomRules/Headers/ActionsMenu'
import FilterByRuleItem from 'components/Dashboard/Profiles/CustomRules/Headers/FilterByRuleItem'
import useQueryString from 'utils/useQueryString'
import { useDeleteGroupMutation, useLazyGetJsonGroupQuery } from 'store/api/groups'
import { useGetRulesQuery } from 'store/api/rules'
import { DialogType } from 'components/Dashboard/Profiles/CustomRules/RuleOrFolderDialog'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

const GroupOptionsSelector = (): ReactElement => {
  const dispatch = useAppDispatch()
  const currentGroup = useAppSelector(s => s.groups.currentGroup)
  const groupForDeletion = useAppSelector(s => s.groups.groupForDeletion)
  const shouldSortRules = useAppSelector(s => s.customRules.shouldSortRules)
  const shouldShowLargeListView = useAppSelector(s => s.customRules.shouldShowLargeView)
  const [isAllActionsMenuVisible, setIsAllActionsMenuVisible] = useState(false)
  const ruleActionFilter = useAppSelector(s => s.customRules.ruleActionFilter)
  const { relocate, nav } = useQueryString()
  const rulesProfile = useGetSelectedProfile()
  const [deleteGroup] = useDeleteGroupMutation()
  const [exportJsonGroup] = useLazyGetJsonGroupQuery()
  const isDesktop = useBreakpointIndex() === 2

  useEffect(() => {
    return () => {
      dispatch(setRuleActionFilter(AllActionType))
    }
  }, [dispatch])

  const groupUserIsIn = useAppSelector(s => s.customRules.groupUserIsIn)
  const { data: rulesData } = useGetRulesQuery(
    {
      profileId: rulesProfile?.PK.toString() ?? '',
      groupPk: groupUserIsIn,
    },
    { skip: !rulesProfile },
  )

  const hideRef = useRef<() => void>()
  const groupOptionsMenuRef = useRef<HTMLDivElement>(null)

  const rulesLength = rulesData?.rules.length || 0

  const options: SimpleActionMenuItemProps[] = [
    {
      ariaLabel: 'edit folder option',
      children: <IconButtonContent startIcon={EditIcon}>Edit Folder</IconButtonContent>,
      onClick: () => {
        if (currentGroup) {
          batch(() => {
            dispatch(setIsCustomRule(false))
            dispatch(updateGroupDraft(currentGroup))
          })
          nav({ ruleDialog: DialogType.RULE_OR_FOLDER })
        }
      },
    },
    {
      ariaLabel: 'delete folder option',
      children: <IconButtonContent startIcon={DeleteIcon}>Delete Folder</IconButtonContent>,
      onClick: async () => {
        await dispatch(setGroupForDeletion(currentGroup?.PK))
      },
    },
    {
      ariaLabel: 'download rules option',
      children: <IconButtonContent startIcon={ExportIcon}>Download Rules</IconButtonContent>,
      onClick: async () => {
        if (currentGroup?.PK && rulesLength > 0 && rulesProfile?.PK) {
          const response = await exportJsonGroup({
            group: currentGroup.PK,
            profileId: rulesProfile.PK.toString(),
          })

          if (response.data?.body) {
            downloadFile({
              content: response.data?.body?.export,
              fileTitle: response.data?.body.filename,
              fileType: 'application/json',
            })
          }
        }
      },
      isDisabled: rulesLength === 0,
    },
    {
      ariaLabel: 'alphabetical option',
      children: (
        <IconButtonContent
          startIcon={SortIcon}
          endIcon={shouldSortRules ? CheckmarkIcon : undefined}
        >
          Alphabetical
        </IconButtonContent>
      ),
      isSelected: shouldSortRules,
      isTopDivider: true,
      onClick: () => {
        dispatch(setShouldSortRules(true))
        hideRef.current?.()
      },
    },
    {
      ariaLabel: 'last updated option',
      children: (
        <IconButtonContent
          startIcon={LastUpdatedIcon}
          endIcon={!shouldSortRules ? CheckmarkIcon : undefined}
        >
          Last Updated
        </IconButtonContent>
      ),
      isSelected: !shouldSortRules,
      onClick: () => {
        dispatch(setShouldSortRules(false))
        hideRef.current?.()
      },
    },
    {
      ariaLabel: 'large list option',
      children: isDesktop ? null : (
        <IconButtonContent
          startIcon={LargeListIcon}
          endIcon={shouldShowLargeListView ? CheckIcon : undefined}
        >
          Large List
        </IconButtonContent>
      ),
      isSelected: shouldShowLargeListView,
      isTopDivider: true,
      onClick: () => {
        dispatch(setShouldShowLargeView(true))
      },
    },
    {
      ariaLabel: 'small list option',
      children: isDesktop ? null : (
        <IconButtonContent
          startIcon={SmallListIcon}
          endIcon={!shouldShowLargeListView ? CheckIcon : undefined}
        >
          Small List
        </IconButtonContent>
      ),
      isSelected: !shouldShowLargeListView,
      onClick: () => {
        dispatch(setShouldShowLargeView(false))
      },
    },
  ]

  useOnClickOutside([groupOptionsMenuRef], () => {
    if (groupForDeletion) {
      dispatch(setGroupForDeletion(undefined))
    }
  })

  const getDeleteRuleConfirmationMessage = (): string => {
    switch (currentGroup?.count) {
      case 0:
        return 'Delete this folder permanently?'
      case 1:
        return 'Delete this folder and the rule inside permanently?'
      default:
        return `Delete this folder and all ${currentGroup?.count || ''} rules
        permanently?`
    }
  }

  return (
    <MoreButton
      options={options}
      ariaLabel="folder options"
      dataTestId="folder-options-selector"
      appendToBoundaryElement="dashboard-sub-header"
      hideRef={hideRef}
      customContent={<FilterByRuleItem setIsAllActionsMenuVisible={setIsAllActionsMenuVisible} />}
      dropdownContent={
        !!groupForDeletion ? (
          <Flex
            ref={groupOptionsMenuRef}
            sx={{
              p: '0.8rem',
              outline: 'none',
              flexDirection: 'column',
            }}
          >
            <Flex
              sx={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Text
                sx={{
                  color: 'aliceBlue60',
                  fontSize: '1.4rem',
                  width: ['25.9rem', '19.9rem'],
                  mr: '0.8rem',
                }}
              >
                {getDeleteRuleConfirmationMessage()}
              </Text>
              <Flex>
                <Button
                  variant="simple"
                  data-testid="delete-folder-button"
                  ariaLabel="delete folder button"
                  onClick={async (): Promise<void> => {
                    if (groupForDeletion && rulesProfile?.PK) {
                      await deleteGroup({
                        profileId: rulesProfile.PK.toString(),
                        group: groupForDeletion,
                      })
                      relocate(location.pathname.slice(0, location.pathname.lastIndexOf('/')))
                      return
                    }
                  }}
                  sx={{ mr: '0.8rem', p: 0, flexShrink: 0 }}
                >
                  <Svg svg={ConfirmDeleteIcon} />
                </Button>
                <Button
                  variant="simple"
                  data-testid="cancel-delete-folder-button"
                  ariaLabel="cancel delete folder button"
                  onClick={(): void => {
                    dispatch(setGroupForDeletion(undefined))
                  }}
                  sx={{ p: 0, flexShrink: 0 }}
                >
                  <Svg svg={CancelDeleteIcon} />
                </Button>
              </Flex>
            </Flex>
          </Flex>
        ) : isAllActionsMenuVisible ? (
          <ActionsMenu
            hideRef={hideRef}
            selectedType={ruleActionFilter}
            setIsAllActionsMenuVisible={setIsAllActionsMenuVisible}
          />
        ) : undefined
      }
    />
  )
}

export default GroupOptionsSelector
