import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import RuleTypeMenu from 'components/Dashboard/Profiles/CustomRules/RuleOrFolderDialog/RuleTypeMenu'
import { Divider, Flex, Spinner, Text } from 'theme-ui'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import isValidDomain from 'is-valid-domain'
import { CustomRuleData, ROOT_GROUP, RuleType } from 'store/api/rules'
import FolderSelectionDropdown from 'components/Dashboard/Profiles/CustomRules/FolderSelectionDropdown'
import { TargetGroup } from 'components/Dashboard/Profiles/CustomRules/MoveRulesMenu'
import { updateCustomRuleDraft } from 'store/customRules'
import ProfileSelectionDropDown, {
  ProfileDropDownItem,
} from 'components/Dashboard/Devices/ProfileSelectionDropDown'
import { isIpValid } from 'utils/isIpOrHostnameValid'
import { useGetDevicesQuery } from 'store/api/devices'
import Comment from './Comment'
import { useGetProfilesQuery } from 'store/api/profiles'
import { createPortal } from 'react-dom'
import StatusChange from './StatusChange'
import omit from 'lodash/omit'
import useQueryString from 'utils/useQueryString'
import { modalFooterRef } from 'ui/NewModalDialog/ModalDialogFooter'
import { useGetExistingDraftRule } from 'components/Dashboard/Profiles/CustomRules/hooks/useGetExistingDraftRule'
import moment from 'moment'

export default function AddEditCustomRules({
  isAddOrEditRuleRequestInFlight,
  isInputValid,
  shouldShowOnlyComment,
  onClick,
  doesDraftRuleExist,
}: {
  isAddOrEditRuleRequestInFlight: boolean
  isInputValid: boolean
  shouldShowOnlyComment: boolean
  onClick?: (ignoreImpersonation?: boolean) => void
  doesDraftRuleExist?: boolean
}): ReactElement {
  const rulesToEdit = useAppSelector(s => s.ruleTray.rulesToEdit)
  const draftRule = useAppSelector(s => s.ruleTray.draftCustomRule)
  const dispatch = useAppDispatch()
  const currentGroupId = useAppSelector(s => s.groups.currentGroup?.PK ?? ROOT_GROUP)
  const currentGroupData = useAppSelector(s => s.groups.currentGroup)

  /* We need to know which profile is linked to the device currently being viewed in Analytics
 for adding rules directly from the Analytics overlay */
  const selectedDeviceId = useAppSelector(s => s.analytics.selectedDeviceId)
  const { data: devicesData } = useGetDevicesQuery('')
  const { data: profilesData } = useGetProfilesQuery('')

  const selectedDeviceForAnalytics = useMemo(
    () => devicesData?.devices?.find(device => device.PK === selectedDeviceId?.replace('-', ':')),
    [devicesData?.devices, selectedDeviceId],
  )

  const [targetGroupForNewRule, setTargetGroupForNewRule] = useState<TargetGroup | undefined>()
  const [selectedProfile, setSelectedProfile] = useState<ProfileDropDownItem>()

  const currentProfile = useMemo(() => {
    let isSharedProfile = false
    //look in profiles
    let profileLinkedToSelectedAnalyticsDevice = profilesData?.profiles?.find(
      profile => profile.PK === (draftRule.profileId || selectedDeviceForAnalytics?.profile.PK),
    )
    //look in shared profiles
    if (!profileLinkedToSelectedAnalyticsDevice) {
      profileLinkedToSelectedAnalyticsDevice = profilesData?.shared_profiles?.find(
        profile => profile.PK === (selectedDeviceForAnalytics?.profile.PK || draftRule.profileId),
      )
      isSharedProfile = true
    }
    if (profileLinkedToSelectedAnalyticsDevice) {
      return {
        ...profileLinkedToSelectedAnalyticsDevice,
        text: profileLinkedToSelectedAnalyticsDevice.name,
        isShared: isSharedProfile,
      }
    }
    return undefined
  }, [draftRule.profileId, profilesData, selectedDeviceForAnalytics?.profile.PK])

  const { areRulesLoading, isAnalytics } = useGetExistingDraftRule()

  useEffect(() => {
    dispatch(
      updateCustomRuleDraft({
        group: targetGroupForNewRule?.PK || ROOT_GROUP,
        ...(targetGroupForNewRule?.PK ? { action: targetGroupForNewRule?.action } : {}),
      }),
    )
  }, [dispatch, targetGroupForNewRule])

  useEffect(() => {
    if (isAnalytics && selectedProfile?.PK.toString()) {
      dispatch(
        updateCustomRuleDraft({
          profileId: selectedProfile?.PK.toString(),
        }),
      )
    }
  }, [dispatch, selectedProfile, isAnalytics])

  useEffect(() => {
    if (currentProfile?.PK && isAnalytics) {
      setSelectedProfile(currentProfile)
    }
  }, [currentProfile, isAnalytics])

  const isButtonDisabled = useMemo(() => {
    // only 1 rule selected
    if ((rulesToEdit?.length ?? 0) <= 1) {
      const selectedRule = rulesToEdit?.[0]
      // if only showing comments for rules inside action folders, disable button if comments same
      if (shouldShowOnlyComment && draftRule.comment === selectedRule?.comment) {
        return true
      }

      if (draftRule?.action?.do === undefined || draftRule?.action?.do === RuleType.RESET) {
        return true
      }

      if (!draftRule?.PK) {
        return true
      }

      if (moment().isSame((draftRule?.action?.ttl || 0) * 1000, 'minute')) {
        return true
      }

      // only enable button if some change is made
      if (
        !!selectedRule &&
        draftRule.action?.do === selectedRule.action.do &&
        draftRule.action?.via === selectedRule.action.via &&
        draftRule.action.via_v6 === selectedRule.action.via_v6 &&
        draftRule.PK === selectedRule.PK &&
        draftRule.action?.ttl === selectedRule.action.ttl &&
        draftRule.comment === selectedRule.comment
      ) {
        return true
      }
    } else {
      // multiple rules selected. should have a do value different from the rules to enable the button
      if (
        (!draftRule?.comment && draftRule?.action?.do === undefined) ||
        rulesToEdit?.every(
          rule =>
            rule.action.do === draftRule?.action?.do &&
            // fixes an issue when comparing a non-existent property and a property that is equal to undefined
            'via' in rule.action &&
            'via' in draftRule?.action &&
            rule.action.via === draftRule?.action?.via &&
            rule.comment === draftRule?.comment,
        )
      ) {
        return true
      }
    }
    if (
      (draftRule?.action?.do === RuleType.SPOOF_IP &&
        // Replacing * with a for the validity check allows us to include * in hostnames without entirely writing our own hostname validity check
        !isValidDomain(draftRule.action?.via ?? '') &&
        !isIpValid(draftRule.action?.via ?? '') &&
        !isIpValid(draftRule.action?.via_v6 ?? '')) ||
      !isInputValid
    ) {
      return true
    }
    if (
      draftRule?.action?.do === RuleType.SPOOF_TAG &&
      !(draftRule?.action?.via || targetGroupForNewRule?.action?.via)
    ) {
      return true
    }

    return false
  }, [
    rulesToEdit,
    draftRule.action,
    draftRule.comment,
    draftRule.PK,
    isInputValid,
    targetGroupForNewRule?.action?.via,
    shouldShowOnlyComment,
  ])

  return (
    <>
      {!shouldShowOnlyComment && (
        <>
          {areRulesLoading ? (
            <Spinner sx={{ mt: '2rem', width: '3.2rem', height: '3.2rem', ml: '1.6rem' }} />
          ) : (
            <RuleTypeMenu
              rule={draftRule}
              forceRuleType={
                targetGroupForNewRule?.PK === ROOT_GROUP
                  ? undefined
                  : currentGroupData && !targetGroupForNewRule
                  ? currentGroupData?.action.do
                  : targetGroupForNewRule?.action?.do
              }
            />
          )}
          <Divider
            sx={{ width: '100%', height: '1px', color: 'blueYonder15', mt: 0, mb: '1.2rem' }}
          />
          {!rulesToEdit?.length && (
            <Flex
              sx={{
                px: '1.6rem',
                flexDirection: 'column',
                mt: currentGroupData?.action.do === undefined ? 0 : '1.6rem',
                mb: '1.2rem',
              }}
            >
              {isAnalytics && (
                <ProfileSelectionDropDown
                  selectedProfile={selectedProfile}
                  setSelectedProfile={setSelectedProfile}
                  label="Choose Profile"
                  description="The Profile in which you want to create this Custom Rule"
                  sx={{ mb: '1.2rem' }}
                  boundaryElementTestId="main-rule-dialog"
                />
              )}
              <FolderSelectionDropdown
                selectedProfileId={selectedProfile?.PK.toString()}
                targetGroup={targetGroupForNewRule}
                setTargetGroup={setTargetGroupForNewRule}
                selectedGroupPK={currentGroupId}
                shouldAllowMovingRuleAnywhere
                isSharedProfile={selectedProfile?.isShared}
              />
            </Flex>
          )}
        </>
      )}
      <Flex sx={{ px: '1.6rem' }}>
        <Comment sx={{ mt: shouldShowOnlyComment ? '1.2rem' : 0 }} />
      </Flex>
      <RuleButton
        isButtonDisabled={isButtonDisabled}
        isAddOrEditRuleRequestInFlight={isAddOrEditRuleRequestInFlight}
        rulesToEdit={rulesToEdit}
        isSelectedProfileShared={selectedProfile?.isShared}
        onClick={onClick}
        doesDraftRuleExist={doesDraftRuleExist}
      />
    </>
  )
}

function RuleButton({
  isButtonDisabled,
  isAddOrEditRuleRequestInFlight,
  rulesToEdit,
  isSelectedProfileShared,
  onClick,
  doesDraftRuleExist,
}: {
  isButtonDisabled: boolean
  isAddOrEditRuleRequestInFlight: boolean
  rulesToEdit?: CustomRuleData[]
  isSelectedProfileShared?: boolean
  onClick?: (ignoreImpersonation?: boolean) => void
  doesDraftRuleExist?: boolean
}): ReactElement | null {
  const { nav, qs } = useQueryString()
  const [shouldAppendButton, setShouldAppendButton] = useState(false)
  const isCustomRule = useAppSelector(s => s.ruleTray.isCustomRule)

  useEffect(() => {
    setShouldAppendButton(!!modalFooterRef.current)
  }, [])

  // adding a button to the dialog footer so as not to move all dependencies and states to the top level component
  return shouldAppendButton && modalFooterRef.current && isCustomRule
    ? createPortal(
        <Flex
          sx={{
            width: '100%',
            justifyContent: (rulesToEdit?.length ?? 0) > 1 ? 'space-between' : 'flex-end',
            flexDirection: ['column', 'row'],
            gap: ['1.2rem', 0],
          }}
        >
          {(rulesToEdit?.length ?? 0) > 1 && (
            <StatusChange
              rulesToEdit={rulesToEdit}
              dismiss={() => {
                nav({ ...omit(qs, 'ruleDialog') })
              }}
            />
          )}
          <ButtonWithLoadingState
            disabled={isButtonDisabled}
            data-testid="rule-tray-submit-button"
            ariaLabel="rule tray submit button"
            variant="newPrimary"
            isLoading={isAddOrEditRuleRequestInFlight}
            sx={{
              minWidth: '6.8rem',
              width: ['100%', 'fit-content'],
              justifyContent: 'center',
              height: '3.2rem',
              px: '1.6rem',
            }}
            sxSpinner={{
              width: '1.6rem',
              height: '1.6rem',
            }}
            onClick={() => {
              onClick?.(isSelectedProfileShared)
            }}
          >
            <Text variant="size15Weight600" sx={{ color: 'black' }}>
              {doesDraftRuleExist
                ? 'Save'
                : !!rulesToEdit?.length
                ? rulesToEdit?.length > 1
                  ? `Apply`
                  : 'Save'
                : 'Create'}
            </Text>
          </ButtonWithLoadingState>
        </Flex>,
        modalFooterRef.current,
      )
    : null
}
