import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { MultiSelectInput, Option, Svg, useAlertPresenter } from 'ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateCustomRuleDraft } from 'store/customRules'
import { Text } from 'theme-ui'
import { CUSTOM_RULES_LIMIT } from 'gatsby-env-variables'
import { sanitizeUrl } from 'utils/sanitizeUrl'
import AddEditCustomRules from './AddEditCustomRule'
import { CustomRuleData, RuleType } from 'store/api/rules/rules.interface'
import isValidDomain from 'is-valid-domain'
import {
  tooltipContent,
  tooltipVisibility,
} from 'components/Dashboard/Profiles/RuleActions/ActionButtonTooltip'
import { setRuleTooltipSettings } from 'store/tutorial/tutorial'
import SpCheckIcon from 'images/sp-check-icon.svg'
import { useGetProfilesQuery } from 'store/api/profiles'
import { useGetProxiesQuery } from 'store/api/proxies'
import {
  ROOT_GROUP,
  useGetAllRulesQuery,
  usePostRulesMutation,
  usePutGroupRulesMutation,
} from 'store/api/rules/rules'
import { useGetGroupsQuery } from 'store/api/groups'
import uniq from 'lodash/uniq'
import { toASCII } from 'utils/punycode'
import customUnescape from 'utils/customUnescape'
import { Flex } from '@theme-ui/components'
import SmallInfoIcon from 'images/dashboard/filters/small-filter-info-icon.svg'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export const isCountryCodeOrASNSpecialRule = (value: string): boolean =>
  /^!?[@#][A-Za-z]{2}\d*?$/.test(value)

export const sanitizeUrls = (value: string | string[]): string => {
  if (Array.isArray(value)) {
    return (
      value
        // don't sanitize if it's a country code or ASN special rule
        .map(v => (isCountryCodeOrASNSpecialRule(v) ? v : sanitizeUrl(v.toString())))
        .join('\n')
    )
  }

  return isCountryCodeOrASNSpecialRule(value) ? value : sanitizeUrl(value)
}

const errorMessage = 'The above domains are invalid.'
const limitErrorMessage = 'Please add 10,000 rules or less at once.'

export default function RuleContent({
  dismiss,
  doesDraftRuleExist,
  isAnalytics,
}: {
  dismiss: () => void
  doesDraftRuleExist?: boolean
  isAnalytics?: boolean
}): ReactElement {
  const dispatch = useAppDispatch()
  const rulesToEdit = useAppSelector(s => s.ruleTray.rulesToEdit)
  const [validationErrorMsg, setValidationErrorMsg] = useState('')
  const draftCustomRule = useAppSelector(s => s.ruleTray.draftCustomRule)
  const [isAddOrEditRuleRequestInFlight, setIsAddOrEditRuleRequestInFlight] = useState(false)
  const [hostnames, setHostnames] = useState<Option[] | undefined>(() =>
    draftCustomRule.PK ? [{ label: draftCustomRule.PK, value: draftCustomRule.PK }] : undefined,
  )
  const { presentAlert, dismissAlert } = useAlertPresenter()
  const { data: userData } = useGetUser()
  const userPk = userData?.PK || ''
  const viewedStateByUserPk = useAppSelector(s => s.tutorial.viewedStateByUserPk[userPk ?? ''])
  const { data: proxiesData } = useGetProxiesQuery('')
  const proxyLocations = proxiesData?.proxies
  const currentGroup = useAppSelector(s => s.groups.currentGroup)
  const currentGroupPk = currentGroup?.PK ?? ROOT_GROUP
  const ruleToEdit = rulesToEdit?.[0]
  const rulesProfile = useGetSelectedProfile()

  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: profilesData } = useGetProfilesQuery('', { skip: !sessionToken })
  const selectedProfileId = draftCustomRule?.profileId ?? rulesProfile?.PK ?? ''

  /**
   * This is used to get the groups of the suborgs profile.Only make this call if the
   * profile being used to create the rule is not a shared profile.
   */
  const { data: groupsData } = useGetGroupsQuery(
    { profileId: selectedProfileId },
    {
      skip:
        !selectedProfileId ||
        !!profilesData?.shared_profiles?.find(
          parentOrgProfile => parentOrgProfile.PK === selectedProfileId,
        ),
    },
  )

  /**
   * This is used to get the groups of the parent org profile (shared profile)
   * when impersonating a sub org. Only make this call if the profile being used
   * to create the rule is a shared profile.
   * This is needed to show the correct group name in the success alert message
   * when adding a rule to a parent org profile while impersonating a sub org profile
   * */
  const { data: parentOrgGroupsData } = useGetGroupsQuery(
    { profileId: draftCustomRule.profileId ?? '', ignoreImpersonation: true },
    {
      skip:
        !draftCustomRule.profileId ||
        !profilesData?.shared_profiles?.find(
          parentOrgProfile => parentOrgProfile.PK === draftCustomRule.profileId,
        ),
    },
  )
  const { data: allRulesData } = useGetAllRulesQuery(
    { profileId: rulesProfile?.PK.toString() ?? '' },
    { skip: !rulesProfile },
  )
  const groupOfRuleToEdit = groupsData?.groups.find(g => g.PK === ruleToEdit?.group)
  const [postRule] = usePostRulesMutation()
  const {
    ruleSelection: { selectedRuleHostnames },
  } = useAppSelector(s => s.customRules)
  // this applies only for rules inside an action folder
  let shouldShowOnlyComment =
    !!ruleToEdit && groupOfRuleToEdit?.action.do !== undefined && ruleToEdit?.group !== ROOT_GROUP
  // for global custom rules search
  if (currentGroupPk === ROOT_GROUP && selectedRuleHostnames.length > 0) {
    shouldShowOnlyComment = selectedRuleHostnames.some(hostname => {
      const groupPk = allRulesData?.rules.find(r => r.PK === hostname)?.group
      const groupData = groupsData?.groups.find(g => g.PK === groupPk)
      return groupData?.action.do !== undefined
    })
  }
  const [putMultipleRule] = usePutGroupRulesMutation()

  useGetProfilesQuery('')

  useEffect(() => {
    setValidationErrorMsg('')
    dispatch(
      updateCustomRuleDraft({
        group: currentGroup?.PK || ROOT_GROUP,
        ...(currentGroup ? { action: currentGroup?.action } : {}),
        ...{ hostname: '' }, // clear hostname only if folder tab is selected
        // do not need to set the do value as it should already be default value
      }),
    )
  }, [dispatch, currentGroup, rulesToEdit])

  const presentSuccessAlert = useCallback(
    (message: string): void => {
      presentAlert(
        {
          message: customUnescape(message),
          icon: SpCheckIcon,
          variant: 'primary',
          timeout: 3000,
          buttons: [
            {
              text: 'Okay',
              onClick: (): void => dismissAlert('rulesAddedOrEditedSuccessfully'),
            },
          ],
          shouldDismissOnClickOutside: true,
        },
        'rulesAddedOrEditedSuccessfully',
      )
    },
    [dismissAlert, presentAlert],
  )

  const onInputBlur = useCallback(
    (hostnames): void => {
      const value = sanitizeUrls(
        hostnames?.filter(({ value }) => value !== '').map(({ value }) => value) || [],
      )

      setValidationErrorMsg(hostnames.length > CUSTOM_RULES_LIMIT ? limitErrorMessage : '')

      dispatch(
        updateCustomRuleDraft({
          PK: value,
        }),
      )
    },
    [dispatch],
  )

  const onInputChange = useCallback(
    (newValue): void => {
      if (!newValue) {
        return
      }

      const rulesLength = hostnames?.length || 0

      setValidationErrorMsg(rulesLength > CUSTOM_RULES_LIMIT ? limitErrorMessage : '')

      dispatch(
        updateCustomRuleDraft({
          PK:
            (hostnames ? hostnames?.map(({ value }) => value)?.join('\n') : '') +
            `${newValue ? `${hostnames ? '\n' : ''}${newValue}` : ''}`,
        }),
      )
    },
    [dispatch, hostnames],
  )

  const presentRuleInfoAlert = useCallback(
    (message: string): void => {
      presentAlert(
        {
          message,
          icon: SpCheckIcon,
          isSticky: true,
          variant: 'primary',
          timeout: 3000,
          buttons: [
            {
              text: 'Okay',
              onClick: (): void => {
                dismissAlert('ruleInfoAlert')
              },
            },
          ],
          shouldDismissOnClickOutside: true,
        },
        'ruleInfoAlert',
      )
    },
    [dismissAlert, presentAlert],
  )

  const onClick = useCallback(
    async (ignoreImpersonation?: boolean): Promise<void> => {
      if (validationErrorMsg) {
        return
      }

      let response

      const updatedCustomRule = {
        ...draftCustomRule,
        action: { ...draftCustomRule.action },
      } as CustomRuleData
      // update draft to remove via if not redirect.  this is needed as we are persisting
      // the old via value during edit / create so that the user can keep cycling through
      // the ruletype buttons without having to re select the via value again.
      if (
        draftCustomRule?.action &&
        draftCustomRule?.action?.do !== RuleType.SPOOF_TAG &&
        draftCustomRule?.action?.do !== RuleType.SPOOF_IP &&
        draftCustomRule?.action?.via
      ) {
        updatedCustomRule.action.via = undefined
        dispatch(updateCustomRuleDraft(updatedCustomRule))
      }
      setIsAddOrEditRuleRequestInFlight(true)

      const filteredHostnames: {
        valid: string[]
        invalid: string[]
      } = {
        valid: [],
        invalid: [],
      }

      if (!rulesToEdit?.length) {
        const hostnames = updatedCustomRule.PK.split(/\r?\n/)

        hostnames.forEach(hostname => {
          if (
            isValidDomain(toASCII(hostname.toString()).replace('*', 'a'), { subdomain: true }) ||
            isCountryCodeOrASNSpecialRule(hostname)
          ) {
            filteredHostnames.valid.push(hostname)
          } else {
            filteredHostnames.invalid.push(hostname)
          }
        })
      }

      filteredHostnames.valid = uniq(filteredHostnames.valid)

      if (filteredHostnames.invalid.length) {
        setIsAddOrEditRuleRequestInFlight(false)
        return setValidationErrorMsg(errorMessage)
      }

      const hostnames = rulesToEdit?.length
        ? rulesToEdit?.map(r => r.PK) ?? []
        : filteredHostnames.valid
      const groupName = (ignoreImpersonation ? parentOrgGroupsData : groupsData)?.groups.find(
        group => group.PK === updatedCustomRule.group,
      )?.group

      const isEditing = !!rulesToEdit?.length || doesDraftRuleExist

      const successMessage = `${hostnames.length} rule${hostnames.length > 1 ? 's' : ''}${
        isEditing ? ' edited' : ' added'
      } ${
        updatedCustomRule.group !== ROOT_GROUP ? `to ${customUnescape(groupName)}` : ''
      } successfully!`

      if (isEditing) {
        response =
          (!!updatedCustomRule?.comment || updatedCustomRule.action.do !== undefined) &&
          (await putMultipleRule({
            body: {
              ...updatedCustomRule.action,
              hostnames: hostnames.map(hostname => toASCII(hostname)),
              group: updatedCustomRule.group || ROOT_GROUP,
              comment: updatedCustomRule.comment,
            },
            profileId:
              (doesDraftRuleExist ? draftCustomRule.profileId : rulesProfile?.PK)?.toString() ?? '',
          }))
      } else {
        response =
          updatedCustomRule.action.do !== undefined &&
          (await postRule({
            body: {
              ...updatedCustomRule.action,
              group: updatedCustomRule.group,
              hostnames: hostnames.map(hostname => toASCII(hostname)),
              ...(updatedCustomRule.comment ? { comment: updatedCustomRule.comment } : undefined),
            },
            profileId: (updatedCustomRule.profileId || rulesProfile?.PK.toString()) ?? '',
            // this is only used for setting a rule in a parent org profile while
            // impersonating a sub org
            ignoreImpersonation,
          }))
        if (filteredHostnames.invalid.length) {
          dispatch(
            updateCustomRuleDraft({
              PK: filteredHostnames.invalid.join('\n'),
            }),
          )
          presentSuccessAlert(successMessage)
          setValidationErrorMsg(errorMessage)
          setIsAddOrEditRuleRequestInFlight(false)
          return
        }
      }

      setIsAddOrEditRuleRequestInFlight(false)

      const error = response.error

      if (error) {
        return
      }

      dismiss()

      if (viewedStateByUserPk?.[tooltipVisibility[response.payload?.do]] === false) {
        dispatch(
          setRuleTooltipSettings({
            userPk,
            selectedRuleType: response.payload?.do ?? RuleType.BLOCK,
            [tooltipVisibility[response.payload?.do ?? RuleType.BLOCK]]: true,
          }),
        )

        const cityName =
          proxyLocations?.find(x => x.PK === response.payload?.via)?.city ||
          'the location you choose'

        presentRuleInfoAlert(
          tooltipContent[response.payload?.do]?.description('the domain', cityName),
        )
      } else {
        presentSuccessAlert(successMessage)
      }
    },
    [
      validationErrorMsg,
      draftCustomRule,
      rulesToEdit,
      parentOrgGroupsData,
      groupsData,
      doesDraftRuleExist,
      dismiss,
      viewedStateByUserPk,
      dispatch,
      putMultipleRule,
      rulesProfile?.PK,
      postRule,
      presentSuccessAlert,
      userPk,
      proxyLocations,
      presentRuleInfoAlert,
    ],
  )

  return (
    <>
      {(rulesToEdit?.length ?? 0) < 2 && !shouldShowOnlyComment && (
        <Flex sx={{ flexDirection: 'column', px: '1.6rem', mt: '1.6rem' }}>
          <Flex
            data-testid="rule-tray-input"
            sx={{
              flexDirection: 'column',
            }}
          >
            <Text
              variant="size12Weight400"
              sx={{
                color: 'aliceBlue70',
                mb: '0.8rem',
              }}
            >
              Domain
            </Text>
            <MultiSelectInput
              testId="rule-tray-input"
              isCustomRule
              isSmallInput
              isDisabled={isAnalytics || !!rulesToEdit?.length}
              isError={!!validationErrorMsg}
              placeholder="Enter domain names"
              aria-label="enter one or more domain nams"
              value={hostnames}
              setValue={setHostnames}
              onInputChange={onInputChange}
              onBlurChange={onInputBlur}
              onChange={() => setValidationErrorMsg('')}
            />
          </Flex>
          <Flex
            data-testid="rule-input-error"
            sx={{
              color: 'errorOpaque',
              fontSize: '1.2rem',
              mt: validationErrorMsg ? '0.8rem' : 0,
              alignItems: 'center',
            }}
          >
            {validationErrorMsg && (
              <Svg
                svg={SmallInfoIcon}
                fill="errorOpaque"
                sx={{ mr: '0.4rem', flexShrink: 0, '& g': { opacity: 1 } }}
              />
            )}
            {validationErrorMsg}
          </Flex>
        </Flex>
      )}
      <AddEditCustomRules
        isAddOrEditRuleRequestInFlight={isAddOrEditRuleRequestInFlight}
        isInputValid={!validationErrorMsg}
        onClick={onClick}
        shouldShowOnlyComment={shouldShowOnlyComment}
        doesDraftRuleExist={doesDraftRuleExist}
      />
    </>
  )
}
