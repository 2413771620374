import React, { ElementType, ReactChild, ReactElement, useState } from 'react'
import { Flex } from 'theme-ui'
import { Dropdown, Svg } from 'ui'
import DropdownIcon from 'images/ui/chevron-up.svg'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { Instance } from 'tippy.js'
import useBreakpointIndex from '../Theme/useBreakpointIndex'
import ExternalSvgIcon, { ExternalIcon } from 'components/Dashboard/ExternalSvgIcon'
import useGetColorMode from 'utils/useGetColorMode'

export default function LogFilterDropdown({
  children,
  isDisabled,
  selectedValue,
  icon,
  externalIcon,
  testId,
  onShow,
  onCreate,
  isLocation,
}: {
  children: ReactChild | ReactChild[]
  isDisabled?: boolean
  selectedValue: string
  icon?: ElementType
  externalIcon?: ExternalIcon
  testId: string
  onShow?: () => void
  onCreate?: (instance: Instance) => void
  isLocation?: boolean
}): ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useBreakpointIndex() === 0
  const { isLightMode } = useGetColorMode()

  return (
    <Flex
      sx={{ position: 'relative' }}
      className={isMobile ? 'reset-tippy-width-subheader mobile' : ''}
    >
      <Dropdown
        data-testid={testId}
        ariaLabel={testId}
        tippyprops={{
          appendTo: 'parent',
          theme: isLightMode ? 'light-org-dropdown' : 'org-dropdown',
          offset: [0, 8],
          onHide: () => {
            setIsOpen(false)
          },
          onShow: () => {
            onShow?.()
            setIsOpen(true)
          },
          onCreate,
          maxWidth: '100%',
          zIndex: 300,
          placement: 'bottom-start',
        }}
        sx={{
          p: 0,
          ':disabled': {
            cursor: 'auto',
            backgroundColor: 'transparent',
            color: 'white6',
            '& span': {
              color: 'white25',
            },
            '& div svg path': {
              fill: 'white25',
            },
            '& > svg path': {
              fill: 'white25',
            },
            ':hover': {
              '& span': {
                color: 'white25',
              },
            },
          },
        }}
        variant="transparent"
        dropdowncontent={isOpen ? children : <></>}
        disabled={isDisabled}
      >
        <Flex
          sx={{
            width: 'fit-content',
            justifyContent: 'space-between',
            fontSize: ['1.6rem', '1.6rem', '1.8rem'],
            color: isOpen ? 'aliceBlue' : 'aliceBlue60',
            alignItems: 'center',
            pr: '0.8rem',
            pl: [0, '0.8rem'],
            py: '0.8rem',
            '& > svg:last-of-type': {
              transform: isOpen ? 'rotate(0)' : 'rotate(180deg)',
            },
            ':hover': {
              '& span': {
                color: 'aliceBlue',
              },
              '& div svg path': {
                fill: !isLocation && 'aliceBlue',
              },
              '& > svg path': {
                fill: 'aliceBlue',
              },
            },
          }}
        >
          <Flex sx={{ alignItems: 'center', gap: '0.8rem' }}>
            {externalIcon && (
              <ExternalSvgIcon
                icon={externalIcon.icon}
                type={externalIcon.type}
                fill={isLocation ? undefined : isOpen ? 'aliceBlue' : 'aliceBlue60'}
                sx={{
                  width: '2.4rem',
                  height: '2.4rem',
                  flexShrink: 0,
                }}
              />
            )}
            {icon && (
              <Svg
                svg={icon}
                fill={isLocation ? undefined : isOpen ? 'aliceBlue' : 'aliceBlue60'}
                sx={{
                  width: '2.4rem',
                  height: '2.4rem',
                  flexShrink: 0,
                }}
              />
            )}
            <TextWithOverFlowAndTippyPopup
              variant="size14Weight700"
              content={selectedValue ?? ''}
              ariaLabel={selectedValue ?? ''}
              sxText={{
                textAlign: 'left',
                maxWidth: '20rem',
                color: isOpen ? 'aliceBlue' : 'aliceBlue60',
              }}
            />
          </Flex>
          <Svg
            svg={DropdownIcon}
            fill={isOpen ? 'aliceBlue' : 'aliceBlue60'}
            sx={{
              width: '1.6rem',
              height: '1.6rem',
              ml: '0.8rem',
              flexShrink: 0,
            }}
          />
        </Flex>
      </Dropdown>
    </Flex>
  )
}
