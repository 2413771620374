import React, { ElementType, ReactChild, ReactElement } from 'react'
import LogFilterDropdown from 'ui/LogItemWrapper/LogFilterDropdown'
import { QueriesFilterMenuType } from '../FiltersContainer'
import { ExternalIcon } from 'components/Dashboard/ExternalSvgIcon'

export const hideQueryFilterDropdown = {}

export default function QueryFilterDropdown({
  children,
  isDisabled,
  selectedValue,
  icon,
  externalIcon,
  type,
  testId,
}: {
  children: ReactChild | ReactChild[]
  isDisabled?: boolean
  selectedValue: string
  icon?: ElementType
  externalIcon?: ExternalIcon
  type?: QueriesFilterMenuType
  testId?: string
}): ReactElement | null {
  return (
    <LogFilterDropdown
      testId={testId || `${type}-queries-filters-dropdown`}
      selectedValue={selectedValue}
      icon={icon}
      externalIcon={externalIcon}
      isDisabled={isDisabled}
      onShow={() => {
        Object.values(hideQueryFilterDropdown)?.map(value => (value as () => void)?.())
      }}
      onCreate={instance => {
        if (type) {
          hideQueryFilterDropdown[type] = instance.hide
        }
      }}
      isLocation={type === QueriesFilterMenuType.LOCATIONS}
    >
      {children}
    </LogFilterDropdown>
  )
}
