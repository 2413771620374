import React, { ReactElement } from 'react'
import { DataRowProps } from 'components/Dashboard/Preferences/CardItems/DataRow.interface'
import { Box, Flex, Text } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'
import { Button, ButtonProps, Svg } from 'ui'
import EncryptedIcon from 'images/encryptedicon.svg'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'

export default function DataRow({
  testId,
  ariaLabelText,
  name,
  data,
  shouldShowCopyToClipboard,
  shouldShowEncryptedIcon,
  smallIcon,
  sxText,
  sxContainer,
  shouldAddBorderBottom,
  textVariant,
}: DataRowProps): ReactElement {
  return (
    <Flex
      data-testid={testId || name}
      sx={{
        position: 'relative',
        flexDirection: ['column', 'row', 'row'],
        alignItems: 'center',
        backgroundColor: 'cardBg',
        borderRadius: [0, '1.2rem'],
        border: '1px solid',
        borderTopColor: 'blueYonder15',
        borderLeftColor: ['transparent', 'blueYonder15'],
        borderRightColor: ['transparent', 'blueYonder15'],
        borderBottomColor: [shouldAddBorderBottom ? 'blueYonder15' : 'transparent', 'blueYonder15'],
        px: '1.2rem',
        py: '1.2rem',
        height: ['auto', '5.4rem'],
        ...sxContainer,
      }}
    >
      <Text
        variant="size15Weight700"
        sx={{
          minWidth: ['100%', '20rem'],
          mr: [0, '1.2rem'],
          color: 'aliceBlue',
          alignItems: 'center',
          ...sxText,
        }}
        tabIndex={name ? 0 : undefined}
      >
        {name}
      </Text>
      <Flex
        sx={{
          flexDirection: 'row',
          flex: 1,
          width: '100%',
          height: '100%',
          alignItems: 'center',
        }}
      >
        {typeof data === 'string' ? (
          <TextWithOverFlowAndTippyPopup
            variant={textVariant || 'size15Weight600'}
            testId="row-data"
            sxText={{
              flex: 10,
              width: '100%',
              color: 'aliceBlue60',
              alignItems: 'center',
            }}
            content={data}
            ariaLabel={data}
          />
        ) : (
          <Flex
            data-testid="row-data"
            sx={{
              flex: 10,
              fontSize: '1.5rem',
              fontWeight: 600,
              color: 'aliceBlue60',
            }}
          >
            {data}
          </Flex>
        )}
        {(shouldShowEncryptedIcon || shouldShowCopyToClipboard) && <Box sx={{ width: '10rem' }} />}
        <Flex
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translate(0, -50%)',
            right: '1.2rem',
            alignItems: 'center',
          }}
        >
          {shouldShowEncryptedIcon && (
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                pl: '0.8rem',
              }}
            >
              <Svg svg={EncryptedIcon} fill="aliceBlue" />
            </Flex>
          )}
          {shouldShowCopyToClipboard && (
            <Flex
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                pl: '0.8rem',
              }}
            >
              <CopyToClipboard
                isButton
                data={data as string}
                ariaLabelText={ariaLabelText}
                smallIcon={smallIcon}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export function ActionButton({
  onClick,
  children,
  sx,
  isAbsolutePosition = true,
  ...rest
}: ButtonProps & { isAbsolutePosition?: boolean }): ReactElement {
  return (
    <Button
      variant="action"
      onClick={onClick}
      sx={{
        position: [isAbsolutePosition ? 'absolute' : 'static', 'static'],
        top: [isAbsolutePosition ? '50%' : 'unset', 'unset'],
        right: [isAbsolutePosition ? '1.2rem' : 'unset', 'unset'],
        transform: [isAbsolutePosition ? 'translate(0, -50%)' : 'unset', 'unset'],
        flexShrink: 0,
        justifyContent: 'flex-end',
        color: 'aliceBlue60',
        '&:hover': {
          ':not([disabled])': {
            color: 'aliceBlue',
          },
        },
        ...sx,
      }}
      {...rest}
    >
      <Text variant="size15Weight600">{children}</Text>
    </Button>
  )
}
