import React, { ElementType, ReactElement, ReactNode } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import SadFaceIcon from 'images/dashboard/sad-face.svg'
import { Svg } from 'ui'

export default function NoResults({
  hideIcon,
  icon,
  iconFill,
  message,
  isSearchText: isSearchText,
  content,
  sx,
  isMessageTextVisible = true,
}: {
  hideIcon?: boolean
  icon?: ElementType
  iconFill?: string
  message?: string
  isSearchText?: boolean
  content?: ReactNode
  sx?: ThemeUIStyleObject
  isMessageTextVisible?: boolean
}): ReactElement {
  const messageText = isSearchText ? 'Are you sure you know what you’re looking for?' : message

  return (
    <Flex
      data-testid="no-results"
      sx={{
        width: '100%',
        mt: ['3.8rem', '5rem'],
        alignItems: 'center',
        flexFlow: 'column',
        flex: '1 1 auto',
        textAlign: 'center',
        gap: '2.4rem',
        ...sx,
      }}
    >
      {!hideIcon && (
        <Svg
          fill={icon ? iconFill : 'aliceBlue60'}
          svg={icon || SadFaceIcon}
          sx={{
            width: '6.8rem',
            height: '6.8rem',
          }}
        />
      )}
      {isMessageTextVisible && (
        <Text sx={{ px: '1.2rem', fontSize: ['1.6rem', '1.8rem'], color: 'aliceBlue60' }}>
          {messageText}
        </Text>
      )}
      {content}
    </Flex>
  )
}
