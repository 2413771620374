import React, { KeyboardEventHandler, ReactElement, useCallback, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { components } from 'react-select'
import { useThemeUI } from 'theme-ui'
import { Setter } from 'utils'
import { ColorModesScale } from '@theme-ui/css/src/types'
import { toUnicode } from 'utils/punycode'
import {
  isCountryCodeOrASNSpecialRule,
  sanitizeUrls,
} from 'components/Dashboard/Profiles/CustomRules/RuleOrFolderDialog/RuleContent'
import { Flex } from '@theme-ui/components'
import { deviceNameMask } from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/AddOrEditDevice/helpers'
import { isValidEmail } from 'utils/isValidEmail'
import ExternalSvgIcon, { IconType } from 'components/Dashboard/ExternalSvgIcon'

export interface Option {
  readonly label: string
  readonly value: string
}

const createOption = (label: string, type: { isCustomRule?: boolean; isDevice?: boolean }) => {
  if (type.isCustomRule) {
    label = sanitizeUrls(isCountryCodeOrASNSpecialRule(label) ? label : toUnicode(label))
  }

  if (type.isDevice) {
    label = deviceNameMask(label)
  }

  return {
    label,
    value: label,
  }
}

const getValuesFromClipboardAndCreateOptions = (
  clipboardData,
  isCustomRule,
  shouldValidateEmail: boolean,
) => {
  return (clipboardData?.getData('text') || '')
    .split(/[\n,]+/)
    .map((value: string) => {
      if (shouldValidateEmail && !isValidEmail(value)) {
        return
      }
      return createOption(value, isCustomRule)
    })
    .filter(Boolean)
}

const getCustomStyles = (
  themeColors?: ColorModesScale,
  isError?: boolean,
  isSmallInput?: boolean,
) => {
  return {
    control: (_, state) => ({
      position: 'relative',
      outline: 'none',
      backgroundColor: themeColors?.blue,
      borderRadius: '0.8rem',
      padding: isSmallInput ? '0.2rem 1.2rem' : '0.8rem 1.2rem',
      paddingRight: '0.4rem',
      border: '1px solid',
      borderColor: isError
        ? themeColors?.errorOpaque
        : state.isFocused
        ? themeColors?.cyan800
        : themeColors?.blueYonder40,
      '&:hover': {
        borderColor: isError
          ? themeColors?.errorOpaque
          : !state.isFocused
          ? themeColors?.blueYonder80
          : 'none',
      },
    }),
    indicatorsContainer: styles => ({
      ...styles,
      display: 'none',
    }),
    valueContainer: styles => ({
      ...styles,
      padding: 0,
      paddingRight: '0.8rem',
      maxHeight: isSmallInput ? '6.8rem' : '10rem',
      minHeight: isSmallInput && '3.2rem',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        position: 'absolute',
        width: '5px',
        backgroundColor: '#222',
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: '3.5px',
        backgroundColor: '#444',
      },
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: themeColors?.white6,
      padding: '0.2rem 0.8rem',
      borderRadius: '45px',
    }),
    multiValueLabel: styles => ({
      ...styles,
      fontSize: '1.2rem',
      fontWeight: 400,
      padding: 0,
      paddingRight: '0.4rem',
      color: themeColors?.aliceBlue60,
    }),
    multiValueRemove: styles => ({
      ...styles,
      padding: 0,
      backgroundColor: 'transparent',
      borderRadius: '50%',
      color: themeColors?.aliceBlue60,
      ':hover': {
        color: themeColors?.aliceBlue,
        cursor: 'pointer',
      },
      '& > svg': {
        width: '1.6rem',
        height: '1.6rem',
      },
    }),
    placeholder: styles => ({
      ...styles,
      padding: '0.2rem',
      fontSize: '1.4rem',
      color: themeColors?.aliceBlue30,
    }),
    input: styles => ({
      ...styles,
      padding: isSmallInput ? 0 : '0.2rem',
      margin: isSmallInput ? 0 : 'unset',
      color: themeColors?.aliceBlue,
    }),
    menu: styles => ({
      ...styles,
      backgroundColor: themeColors?.raisinBlack,
      borderRadius: '1.2rem',
      border: `1px solid ${themeColors?.blueYonder30}`,
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.06)',
      padding: '0 0.4rem',
    }),
    menuList: styles => ({
      ...styles,
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 0,
      paddingRight: 0,
      gap: '0.1rem',
    }),
    option: styles => ({
      ...styles,
      fontSize: '1.4rem',
      cursor: 'pointer',
      padding: '0.8rem 0.4rem',
      color: themeColors?.aliceBlue60,
      backgroundColor: 'transparent',
      borderRadius: '0.8rem',
      '&:hover': {
        color: themeColors?.white,
        backgroundColor: themeColors?.littleBoyBlue5,
      },
      '&:active': {
        backgroundColor: 'transparent',
      },
    }),
  }
}

const MultiValue = ({ children, ...props }) => {
  return (
    <components.MultiValue {...props}>
      <Flex sx={{ alignItems: 'center', gap: '0.8rem' }}>
        {isCountryCodeOrASNSpecialRule(children) && (
          <ExternalSvgIcon
            icon={children.replace(/^!|@|#/g, '')}
            defaultColor="aliceBlue60"
            type={IconType.LOCATIONS}
            sx={{ width: '1.6rem', height: '1.6rem' }}
          />
        )}
        {children}
      </Flex>
    </components.MultiValue>
  )
}

export default function MultiSelectInput({
  placeholder,
  value,
  setValue,
  testId,
  ariaLabel,
  isError,
  isSmallInput,
  isCustomRule,
  isDevice,
  isDisabled = false,
  onInputChange,
  onChange,
  onBlurChange,
  options,
  menuIsOpen,
  shouldValidateEmail = false,
}: {
  placeholder?: string
  value?: Option[] | undefined
  setValue?: Setter<Option[] | undefined>
  testId?: string
  ariaLabel?: string
  isError?: boolean
  isSmallInput?: boolean
  isCustomRule?: boolean
  isDevice?: boolean
  isDisabled?: boolean
  onInputChange?: (newValue?: string) => void
  onChange?: (event?: React.ChangeEvent<HTMLSelectElement>) => void
  onBlurChange?: (value?: Option[]) => void
  options?: Option[]
  menuIsOpen?: boolean
  shouldValidateEmail?: boolean
}): ReactElement {
  const [inputValue, setInputValue] = useState('')
  const { theme } = useThemeUI()

  const handleOptionCreation = useCallback(
    event => {
      event.preventDefault()

      if (event.type === 'paste') {
        if (!isCustomRule && event.target.ariaLabel !== ariaLabel) {
          return
        }

        const options = getValuesFromClipboardAndCreateOptions(
          event.clipboardData,
          isCustomRule,
          shouldValidateEmail,
        )

        onBlurChange?.(options)

        return setValue?.(prev => {
          if (prev) {
            return [...prev, ...options]
          }

          return [...options]
        })
      }

      setValue?.(prev => {
        if (shouldValidateEmail && !isValidEmail(inputValue)) {
          return prev
        }

        if (prev) {
          return [...prev, createOption(inputValue, { isCustomRule, isDevice })]
        }

        return [createOption(inputValue, { isCustomRule, isDevice })]
      })

      setInputValue('')
    },
    [onBlurChange, ariaLabel, inputValue, isCustomRule, setValue, isDevice, shouldValidateEmail],
  )

  const handleKeyDown: KeyboardEventHandler = event => {
    if (!inputValue) {
      return
    }

    if (event.type === 'blur') {
      handleOptionCreation(event)
    }

    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ',':
        handleOptionCreation(event)
    }
  }

  const customStyles = getCustomStyles(theme.colors, isError, isSmallInput)

  return (
    <div data-testid={'paste-listener'} onPaste={event => handleOptionCreation(event)}>
      <CreatableSelect
        aria-label={ariaLabel}
        data-testid={testId}
        styles={customStyles}
        components={{ MultiValue }}
        inputValue={inputValue || ''}
        isClearable
        isMulti
        focus={true}
        isDisabled={isDisabled}
        menuIsOpen={menuIsOpen ? undefined : false}
        onChange={newValue => {
          setValue?.(newValue)
          onChange?.()
        }}
        onInputChange={newValue => {
          setInputValue(newValue)
          onInputChange?.(newValue)
        }}
        onBlur={e => {
          const targetValue = e.target.value

          handleKeyDown(e)

          onBlurChange?.([...(value || []), { label: targetValue, value: targetValue }])
        }}
        onKeyDown={handleKeyDown}
        placeholder={placeholder || 'Type something and press enter...'}
        value={value || ''}
        options={options}
        noOptionsMessage={() => null}
        isValidNewOption={(inputValue: string) => {
          if (!shouldValidateEmail) {
            return true
          }

          return isValidEmail(inputValue)
        }}
      />
    </div>
  )
}
