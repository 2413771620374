import React, { Dispatch, ReactElement } from 'react'
import { Box, Divider, Flex, Text } from 'theme-ui'
import AddOrEditDeviceTabs, {
  DeviceTab,
} from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDeviceTabs'
import { ActionType, DeviceActionType, InitialStateType } from '../SettingsState'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import DeviceTypeDropdown from './DeviceTypeDropdown'
import { ExternalLink, Input, Option, Svg } from 'ui'
import MultipleProfiles from '../MultipleProfiles'
import useGetUserState from 'store/api/user/useGetUserState'
import { Setter } from 'utils'
import useQueryString from 'utils/useQueryString'
import AddOrEditDeviceInput from './AddOrEditDeviceInput'
import ScheduleStatus from 'components/Dashboard/Devices/DeviceListItem/ScheduleStatus'
import SettingsItem from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/SettingsItem'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import ConnectedDevicesIcon from 'images/organization/connected-devices.svg'
import { Link } from '@reach/router'
import InfoIcon from 'images/dashboard/info-icon.svg'

export default function AddOrEditDeviceMainContent({
  devicePk,
  settingsState,
  settingsDispatch,
  selectedTab,
  setSelectedTab,
  namesOrEmails,
  setNamesOrEmails,
  organizationName,
  isClient,
  globalProfilePk,
}: {
  devicePk?: string
  settingsState: InitialStateType
  settingsDispatch: Dispatch<ActionType>
  selectedTab: DeviceTab
  setSelectedTab: Setter<DeviceTab>
  namesOrEmails?: Option[]
  setNamesOrEmails: Setter<Option[] | undefined>
  organizationName?: string
  isClient?: boolean
  globalProfilePk?: string
}): ReactElement {
  const { qs } = useQueryString()
  const { isOrganization } = useGetUserState()

  return (
    <>
      {isOrganization && !devicePk && (
        <>
          <Flex
            sx={{
              width: '100%',
              flexDirection: 'row',
              px: '1.6rem',
              pb: '0.4rem',
              gap: '0.8rem',
            }}
          >
            <Svg
              svg={InfoIcon}
              fill="aliceBlue60"
              sx={{
                width: '1.6rem',
                height: '1.6rem',
                flexShrink: 0,
              }}
            />
            <Text variant="size12Weight400" sx={{ color: 'aliceBlue60' }}>
              Need multiple Endpoints using RMM?{' '}
              <Link
                to={`/dashboard/provision?provisionDialog=add${
                  qs.orgId ? `&orgId=${qs.orgId}` : ''
                }`}
                style={{
                  color: 'inherit',
                }}
                data-testid="provision-button"
                aria-label="create provisioning code"
              >
                Create Provisioning Code
              </Link>
            </Text>
          </Flex>
          <Divider sx={{ color: 'blueYonder15', m: 0 }} />
        </>
      )}
      <Flex sx={{ width: '100%', flexDirection: 'column', px: '1.6rem', gap: '1.2rem' }}>
        {isOrganization && !devicePk && (
          <AddOrEditDeviceTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            resetDeviceInput={() => {
              settingsDispatch({
                type: DeviceActionType.RESET_SETTINGS,
                payload: undefined,
              })

              if (qs.stats) {
                settingsDispatch({
                  type: DeviceActionType.STATS,
                  payload: +qs.stats,
                })
              }
            }}
          />
        )}
        {isOrganization && devicePk && (
          <Flex
            sx={{
              width: '100%',
              py: '0.8rem',
              px: '1.2rem',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0.8rem',
              backgroundColor: 'blue',
            }}
          >
            <Flex
              sx={{
                flexDirection: ['column', 'row'],
              }}
            >
              <Text
                variant="size16Weight400"
                sx={{
                  flexShrink: 0,
                  mx: 'auto',
                  whiteSpace: 'pre-wrap',
                  color: 'aliceBlue',
                }}
              >
                Organization: {''}
              </Text>
              <TextWithOverFlowAndTippyPopup
                sxTooltipContent={{ wordWrap: 'break-word' }}
                maxWidth="67rem"
                variant="size16Weight700"
                sxText={{
                  textAlign: 'center',
                  color: 'aliceBlue',
                }}
                content={organizationName}
                ariaLabel={organizationName || ''}
              />
            </Flex>
          </Flex>
        )}
        {isOrganization && (
          <SettingsItem
            testId="connected-devices"
            icon={ConnectedDevicesIcon}
            title="Connected Devices"
            description="How many devices will connect to this Endpoint?"
            descriptionLink={
              <ExternalLink
                to={`${DOCS_CONTROL_D_DOMAIN}/docs/connected-devices`}
                sx={{
                  color: 'aliceBlue60',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  whiteSpace: 'nowrap',
                }}
              >
                Learn more
              </ExternalLink>
            }
            sx={{ '&>div': { px: '0rem' } }}
            actionContent={
              <Box
                data-testid="settings-connected-devices-container"
                sx={{ position: 'relative', flexShrink: 0 }}
                className="reset-tooltip"
              >
                <Input
                  isCompact
                  name="client-count-input"
                  data-testid="client-count-input"
                  aria-label="client count input"
                  value={settingsState.client_count}
                  sx={{
                    maxWidth: '6rem',
                  }}
                  onChange={(event): void => {
                    settingsDispatch({
                      type: DeviceActionType.CLIENT_COUNT,
                      payload: +event.target.value ? +event.target.value : 0,
                    })
                  }}
                />
              </Box>
            }
          />
        )}
        <Flex sx={{ width: '100%', gap: '1.2rem' }}>
          <DeviceTypeDropdown
            selectedType={settingsState.icon}
            setSelectedType={type => {
              settingsDispatch({
                type: DeviceActionType.ICON,
                payload: type,
              })
            }}
            isClient={isClient}
          />
          <AddOrEditDeviceInput
            devicePk={devicePk}
            settingsDispatch={settingsDispatch}
            settingsStateName={settingsState.name}
            namesOrEmails={namesOrEmails}
            setNamesOrEmails={setNamesOrEmails}
          />
        </Flex>

        <MultipleProfiles
          settingsDispatch={settingsDispatch}
          settingsState={settingsState}
          devicePk={devicePk}
          globalProfilePk={globalProfilePk}
        />
        <ScheduleStatus devicePk={devicePk} />
      </Flex>
      <Divider sx={{ color: 'blueYonder15', m: 0 }} />
      <Flex sx={{ width: '100%', px: '1.6rem' }}>
        <Input
          name="comment-input"
          data-testid="comment-input"
          aria-label="comment input"
          label="Comments"
          value={settingsState.desc}
          rightContent={
            <Text variant="size12Weight400" sx={{ mr: '1.6rem', color: 'aliceBlue50' }}>
              Optional
            </Text>
          }
          sx={{
            maxHeight: '3.8rem',
          }}
          onChange={(event): void => {
            settingsDispatch({
              type: DeviceActionType.COMMENT,
              payload: event.target.value,
            })
          }}
        />
      </Flex>
    </>
  )
}
