import React, {
  ReactElement,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { Option, useCustomAlerts } from 'ui'
import { useAppDispatch } from 'store/hooks'
import { setDeviceForEdit } from 'store/multiprofile'
import {
  ActionType,
  DeviceActionType,
  initialState,
  InitialStateType,
  settingsReducer,
} from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/SettingsState'
import useQueryString from 'utils/useQueryString'
import { DeviceDialogType } from 'components/Dashboard/Devices/DeviceModalDialog'
import { EnabledStatus } from 'store/api/rules'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { useGetStatLevelsQuery } from 'store/api/analytics'
import { DeviceTab } from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDeviceTabs'
import { useEditOrganizationMutation, useGetOrganizationQuery } from 'store/api/organization'
import AdvancedSettings from 'components/Dashboard/Devices/DeviceModalDialog/AddOrEditDevice/AdvancedSettings'
import { usePutUserMutation } from 'store/api/user'
import useGetUserState from 'store/api/user/useGetUserState'
import {
  useGetDevicesTypesQuery,
  usePostDeviceMutation,
  usePutDeviceMutation,
} from 'store/api/devices/devices'
import { DeviceInfo } from 'store/api/devices/devices.interface'
import { NukeAnalyticsType } from 'store/api/user/user.interface'
import DeviceSettings from '../DeviceSettings'
import AddOrEditDeviceButtons from './AddOrEditDeviceButtons'
import AddOrEditDeviceMainContent from './AddOrEditDeviceMainContent'
import {
  ddnsSubdomainMinLength,
  getRequestPayloadFromState,
  isDeactivationPinLengthValid,
} from './helpers'
import { Flex } from '@theme-ui/components'
import { SetupGuideIntermediateStates, SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import pick from 'lodash/pick'
import SelectRegionWarningMessage from 'components/SelectRegionWarningMessage'

const AddOrEditDeviceView = ({
  device,
  dismiss,
  onDeleteClick,
}: {
  device?: Partial<DeviceInfo>
  dismiss: () => void
  onDeleteClick?: (devicePk: string) => void
}): ReactElement => {
  const dispatch = useAppDispatch()
  const { qs, nav } = useQueryString()
  const [isRequestInFlight, setIsRequestInFlight] = useState(false)
  const [selectedTab, setSelectedTab] = useState(DeviceTab.CLIENT)
  /**
   * For retail users, session is the only place from which we can get the statistics storage region
   * For org users, the region is present in the user object but we should use the one present in the
   * organization data since we need to handle impersonation
   */
  const { isOrganization, region: userStatisticsRegion } = useGetUserState()
  const { data: orgData } = useGetOrganizationQuery('', { skip: !isOrganization })
  const orgStatisticsRegion = orgData?.organization.stats_endpoint
  const isClient = device ? !device?.icon?.includes('router') : selectedTab === DeviceTab.CLIENT
  const [loadingMessage, setLoadingMessage] = useState('')
  const { nuke_analytics: nukeAnalytics } = useGetUserState()
  const { data: typesData } = useGetDevicesTypesQuery('')
  const [editOrganization] = useEditOrganizationMutation()
  const [putDevice] = usePutDeviceMutation()
  const [postDevice] = usePostDeviceMutation()
  const organizationName = orgData?.organization?.name
  const [settingsState, settingsDispatch] = useReducer<Reducer<InitialStateType, ActionType>>(
    settingsReducer,
    {
      ...initialState,
      ...device,
      deactivationStatus: !!device?.deactivation_pin
        ? EnabledStatus.ENABLED
        : EnabledStatus.DISABLED,
    } as InitialStateType,
  )
  const containerRef = useRef<HTMLDivElement>(null)

  const [namesOrEmails, setNamesOrEmails] = useState<Option[] | undefined>(() =>
    settingsState.name ? [{ label: settingsState.name, value: settingsState.name }] : undefined,
  )

  const isSubdomainValid = !!(
    settingsState.ddns?.status &&
    (settingsState.ddns.subdomain?.length || 0) < ddnsSubdomainMinLength
  )

  const isDeactivationPinInvalid =
    settingsState.deactivationStatus === EnabledStatus.ENABLED &&
    !isDeactivationPinLengthValid(settingsState.deactivation_pin)

  // need statlevels to be loaded when tray is opened from analytics page
  useGetStatLevelsQuery('')
  const [putUser] = usePutUserMutation()
  const shouldShowWarningMessage =
    !userStatisticsRegion &&
    (settingsState.stats === StatLevel.FULL || settingsState.stats === StatLevel.SOME)

  useEffect(() => {
    if (!device) {
      if (qs.legacyIpv4Status !== undefined) {
        settingsDispatch({
          type: DeviceActionType.LEGACY_RESOLVER,
          payload: +qs.legacyIpv4Status,
        })
      }

      if (qs.learnIp !== undefined) {
        settingsDispatch({
          type: DeviceActionType.AUTO_AUTH_IP,
          payload: +qs.learnIp,
        })
      }

      if (qs.stats) {
        settingsDispatch({
          type: DeviceActionType.STATS,
          payload: +qs.stats,
        })
      }
    }
  }, [device, typesData?.types, qs.legacyIpv4Status, qs.stats, qs.learnIp])

  useEffect(() => {
    if (qs.deviceDialog === DeviceDialogType.EDIT && !device) {
      dispatch(setDeviceForEdit())
      dismiss()
    }
  }, [dismiss, dispatch, qs.deviceDialog, device])

  useEffect(() => {
    if (!settingsState?.ddns_ext?.status) {
      settingsDispatch({
        type: DeviceActionType.DDNS_HOST,
        payload: device?.ddns_ext?.host || '',
      })
    }
  }, [settingsState?.ddns_ext?.status, device])

  useEffect(() => {
    if (!settingsState?.ddns?.status) {
      settingsDispatch({
        type: DeviceActionType.EXPOSE_IP_SUBDOMAIN,
        payload: device?.ddns?.subdomain || '',
      })
    }
  }, [settingsState?.ddns?.status, device])

  useEffect(() => {
    if (qs?.learnIp === undefined && !settingsState?.legacy_ipv4?.status) {
      settingsDispatch({
        type: DeviceActionType.AUTO_AUTH_IP,
        payload: device?.learn_ip || EnabledStatus.DISABLED,
      })
    }
  }, [qs?.learnIp, settingsState?.legacy_ipv4?.status, device])

  useEffect(() => {
    if (!!settingsState?.restricted) {
      settingsDispatch({
        type: DeviceActionType.AUTO_AUTH_IP,
        payload: EnabledStatus.DISABLED,
      })
    }
  }, [device, settingsState?.restricted])

  useEffect(() => {
    if (isOrganization) {
      settingsDispatch({
        type: DeviceActionType.CLIENT_COUNT,
        payload: device?.client_count || (isClient ? 1 : 5),
      })
    }
  }, [device?.client_count, isOrganization, isClient])

  const getUpdatedRequestDevice = useCallback(
    (requestDevice, deviceNameOrEmail) => {
      requestDevice.name = deviceNameOrEmail || ''

      if (qs.clientId) {
        //this is a client being changed to a device
        // add the profile ids to the payload
        if (settingsState.profile) {
          requestDevice.profile_id = settingsState.profile.PK.toString()
        }
        if (settingsState.profile2) {
          requestDevice.profile_id2 = settingsState.profile2.PK.toString()
        }
        // add the stat to the payload
        if (settingsState.stats) {
          requestDevice.stats = settingsState.stats
        }
        requestDevice.remap_client_id = settingsState.remap_client_id
        requestDevice.remap_device_id = settingsState.remap_device_id
      }

      return requestDevice
    },
    [
      qs.clientId,
      settingsState.profile,
      settingsState.profile2,
      settingsState.remap_client_id,
      settingsState.remap_device_id,
      settingsState.stats,
    ],
  )

  const areOptionsChanged = useMemo(
    () => !!Object.entries({ ...getRequestPayloadFromState(settingsState, device) }).length,
    [device, settingsState],
  )

  const createMultipleDevices = useCallback(
    async requestDevice => {
      let response

      for (let i = 0; i < (namesOrEmails?.length || 0); i++) {
        setLoadingMessage(`Creating endpoints ${i + 1}/${namesOrEmails?.length || 0}`)
        requestDevice = getUpdatedRequestDevice(requestDevice, namesOrEmails?.[i]?.value)

        response = await postDevice({ newDevice: requestDevice })

        if (response?.error) {
          setLoadingMessage('')
          setIsRequestInFlight(false)
          break
        }

        if (settingsState.name) {
          settingsState.name = settingsState.name?.split('\n')?.slice(1).join('\n')
        }
      }

      if (!response?.error) {
        setLoadingMessage('')
        dismiss()

        if (namesOrEmails?.length === 1) {
          nav({
            ...pick(qs, 'orgId'),
            deviceId: response.data.PK,
            helpPane: SetupGuideIntermediateStates.DNS,
            setupOs: requestDevice.icon as SetupOs,
          })
        }
      }

      /**
       * settingsState.regionSettings will only be set if the user set it using the region
       * selection dropdown. This is when we would call `PUT /user` to update the analytics
       * region for the user.
       */
      if (settingsState.regionSettings && !qs.clientId) {
        if (nukeAnalytics === NukeAnalyticsType.USER) {
          await putUser(settingsState.regionSettings)
        } else {
          editOrganization(settingsState.regionSettings)
        }
      }
    },
    [
      settingsState,
      qs,
      namesOrEmails,
      getUpdatedRequestDevice,
      postDevice,
      dismiss,
      nav,
      nukeAnalytics,
      putUser,
      editOrganization,
    ],
  )
  const { presentCautionOkAlert } = useCustomAlerts()
  const handleSubmit = useCallback(
    async (event?: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      event?.preventDefault()

      const isAnalyticsInvalid = !(
        settingsState.stats === StatLevel.NO ||
        (orgStatisticsRegion ?? userStatisticsRegion) ||
        settingsState.regionSettings
      )

      if (isAnalyticsInvalid) {
        presentCautionOkAlert('Please select a valid analytics region.')
        return
      }
      const requestDevice = {
        ...getRequestPayloadFromState(settingsState, device),
      } as unknown as DeviceInfo

      setIsRequestInFlight(true)

      let response

      if (device?.PK) {
        response = await putDevice({ PK: device.PK, device: requestDevice as DeviceInfo })
      } else {
        await createMultipleDevices(requestDevice)
        return
      }

      /**
       * settingsState.regionSettings will only be set if the user set it using the region
       * selection dropdown. This is when we would call `PUT /user` to update the analytics
       * region for the user.
       */
      if (settingsState.regionSettings && !qs.clientId) {
        if (nukeAnalytics === NukeAnalyticsType.USER) {
          await putUser(settingsState.regionSettings)
        } else {
          editOrganization(settingsState.regionSettings)
        }
      }

      if (!response.error) {
        dismiss()
        dispatch(setDeviceForEdit())
      }

      setIsRequestInFlight(false)
    },
    [
      settingsState,
      orgStatisticsRegion,
      userStatisticsRegion,
      device,
      qs,
      presentCautionOkAlert,
      putDevice,
      createMultipleDevices,
      nukeAnalytics,
      putUser,
      editOrganization,
      dismiss,
      dispatch,
    ],
  )

  return (
    <Flex
      ref={containerRef}
      as="form"
      // @ts-ignore
      onSubmit={handleSubmit}
      sx={{
        position: 'relative',
        width: ['100%', '43.5rem'],
        maxHeight: ['calc(100% - 0.2rem)', 'calc(100vh - 18rem)'],
        pt: shouldShowWarningMessage ? 0 : '1.2rem',
        gap: '1.2rem',
        overflowY: 'auto',
        flexDirection: 'column',
        alignSelf: 'flex-start',
      }}
      className="show-scrollbar"
    >
      {shouldShowWarningMessage && <SelectRegionWarningMessage />}
      <AddOrEditDeviceMainContent
        devicePk={device?.PK}
        settingsState={settingsState}
        settingsDispatch={settingsDispatch}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        namesOrEmails={namesOrEmails}
        setNamesOrEmails={setNamesOrEmails}
        isClient={isClient}
        organizationName={organizationName}
        globalProfilePk={orgData?.organization?.parent_profile?.PK}
      />

      <AdvancedSettings>
        <DeviceSettings
          settingsState={settingsState}
          settingsDispatch={settingsDispatch}
          isStatusVisible={!!device && !qs.clientId}
          deviceDeactivationPin={device?.deactivation_pin}
          deviceLegacyIpv4Status={device?.legacy_ipv4?.status}
          deviceRestricted={device?.restricted}
        />
      </AdvancedSettings>
      <AddOrEditDeviceButtons
        creatingDeviceText={loadingMessage}
        devicePk={device?.PK}
        isSaveButtonDisabled={isSubdomainValid || isDeactivationPinInvalid || !areOptionsChanged}
        isCreateButtonDisabled={
          !namesOrEmails?.length ||
          !settingsState.profile ||
          isSubdomainValid ||
          !!loadingMessage ||
          isDeactivationPinInvalid ||
          !settingsState.icon ||
          (isOrganization && !settingsState.client_count) ||
          shouldShowWarningMessage
        }
        isSavingDeviceLoading={isRequestInFlight}
        isCreatingDeviceLoading={isRequestInFlight}
        onSubmit={handleSubmit}
        onDeleteClick={onDeleteClick}
      />
    </Flex>
  )
}

export default AddOrEditDeviceView
