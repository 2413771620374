import React, { ReactElement, ReactNode } from 'react'
import { Text, Flex } from 'theme-ui'
import { customRulesUITitle } from 'store/customRules'

interface CustomRulesTitleProps {
  children: ReactNode
}
export default function CustomRulesTitle({ children }: CustomRulesTitleProps): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'default',
        ml: '-0.2rem',
      }}
    >
      <Text
        data-testid="info-title"
        className="info-title fill-available"
        sx={{
          flex: 1,
          fontSize: ['2.0rem', '2.0rem', '2.8rem'],
          fontWeight: 'bold',
          mr: '0.4rem',
          // small space for nice focus state
          px: [0, 0, '0.2rem'],
          flexShrink: ['initial', 0],
          alignSelf: ['center', 'auto'],
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: customRulesUITitle.WHEN_DRAG === children ? 'greenApple' : 'inherit',
        }}
      >
        {children}
      </Text>
    </Flex>
  )
}
