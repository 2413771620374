import React, { ReactElement, useEffect, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import { Link } from 'gatsby'
import { Accordion } from 'ui'
import { MobileNavMenuContentType } from 'ui/MobileNavMenuTray'
import MobileNavMenuLinkItem from './MobileNavMenuLinkItem'
import ArrowUpIcon from 'images/organization/homepage/headerNavMenu/arrow-sm-up.svg'
import { useLocation } from '@reach/router'

const MobileNavMenuItem = ({
  title,
  links,
  to = '',
  tabIndex,
  dismiss,
}: MobileNavMenuContentType): ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    links && setIsOpen(false)
  }, [links, dismiss])

  return (
    <Flex
      key={title}
      data-testid={`nav-menu-${title}`}
      sx={{
        backgroundColor: isOpen ? 'wakaiwaGrey05' : 'transparent',
        color: 'lightSteelBlue',
        flexDirection: 'column',
        flexShrink: 0,
        ':last-child': {
          'a > span': {
            color: 'cyan800',
          },
        },
      }}
    >
      {links ? (
        <Accordion
          id={title}
          data-testid="nav-menu-accordion"
          tabIndex={tabIndex}
          title={
            <Text
              sx={{
                color: isOpen ? 'successOpaque' : 'lightSteelBlue',
                fontSize: '1.8rem',
                lineHeight: '140%',
                fontWeight: 500,
                fontFamily: 'body',
              }}
            >
              {title}
            </Text>
          }
          icon={ArrowUpIcon}
          fillColor={isOpen ? 'successOpaque' : 'lightSteelBlue'}
          titleStyle={{
            minWidth: '100%',
            textAlign: 'left',
          }}
          containerStyle={{
            minWidth: '100%',
            borderBottom: '1px solid',
            borderColor: 'wakaiwaGrey50',
          }}
          buttonStyle={{
            justifyContent: 'space-between',
            fontSize: '1.8rem',
            fontWeight: 500,
            px: '2.4rem',
            py: '1.6rem',
            '@media (hover: hover)': {
              ':hover': {
                backgroundColor: 'wakaiwaGrey20',
              },
            },
          }}
          svgStyle={{
            p: 0,
          }}
          isOpenControlled={isOpen}
          setIsOpenControlled={setIsOpen}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              width: '100%',
              px: '2.4rem',
              pb: '1.6rem',
            }}
          >
            {links.map(link => (
              <MobileNavMenuLinkItem
                key={link.title}
                to={link.to}
                href={link.href}
                title={link.title}
                leftIcon={link.leftIcon}
                dataTestId={link.title}
                dismiss={dismiss}
              />
            ))}
          </Flex>
        </Accordion>
      ) : (
        <Flex
          onClick={dismiss}
          sx={{
            borderBottom: '1px solid',
            borderColor: 'wakaiwaGrey50',
            fontSize: '1.8rem',
            fontWeight: 500,
            cursor: 'pointer',
            ':hover': {
              backgroundColor: 'wakaiwaGrey20',
            },
          }}
        >
          <Link
            to={to}
            state={{ prevPath: location.pathname }}
            title={title}
            data-testid={`nav-menu-link-${title}`}
            tabIndex={tabIndex}
            style={{
              width: '100%',
              padding: '1.6rem 2.4rem',
              textDecoration: 'none',
            }}
          >
            <Text
              sx={{
                color: 'lightSteelBlue',
                fontSize: '1.8rem',
                fontWeight: 500,
                lineHeight: '140%',
                fontFamily: 'body',
              }}
            >
              {title}
            </Text>
          </Link>
        </Flex>
      )}
    </Flex>
  )
}

export default MobileNavMenuItem
