import { alpha } from '@theme-ui/color'

// for new colors use https://colornamer.robertcooper.me/  (in dropdown choose option "Wikipedia Color Names")
const basePalette = {
  // dark and light mode
  greenApple: '#1BE3AD', // green
  blue800: '#010818', // body bg
  plumpPurple: '#593EA5', // purple
  aliceBlue: '#E8EFFF', // text, icon
  littleBoyBlue: '#7696E0', // border & divider selected
  white: '#ffffff', // tertiary button cta
  silverSpoon: '#D2D2D2', // toggle bg
  darkJungleGreen: '#182132', // (#102134)
  black: '#000000', // toggle knob bg
  blue: '#050C1D', // rule record and comment bg
  cyan800: '#63D6FF', // input field border focus
  errorOpaque: '#E3571B', // input field border error
  limeGreen: '#18C13E',
  isabelline: '#EBECF3',
  culturedPearl: '#F5F5F5',
  grayLogoBG: '#2f3241',
  darkItemBG: '#252737',
  // -----

  darkBodyBG: '#12131c',
  darkItemBoxShadow: '#0d0e13',
  plum: '#6e34e9',
  green: '#1fbf10',
  secondaryHoverBG: '#9267ef',
  pomegranate: '#E93349',
  purpleHeart: '#b81edf',
  blueRibbon: '#1949f3',
  eggplant: '#9b2edd',
  banana: '#D6A62D',
  berryBlue: '#00b7ff',
  transparent: 'transparent',
  pieChartNoData: '#2A2B32',
  grayBG: '#252737',
  unavailableBG: '#92939b',
  blue700: '#1F0A6D',
  blue600: '#4A20E5',
  blue400: '#C6D7FF',
  cyan900: '#007AA6',
  cyan700: '#A7E8FF',
  cyan600: '#DFF7FF',
  lightSteelBlue: '#AAB8D9',
  columbiaBlue: '#D0E4F3',
  lightSkyBlue: '#76DBFF',
  turquoise: '#3BD7E6',
  blueYonder: '#5C6D96',
  airSuperiorityBlue: '#6AA2B6',
  steelPink: '#CD3BE6',
  mauve: '#DEABFF',
  aquamarine: '#66ffff',
  lavenderBlue: '#c6d7ff',
  ceruleanFrost: '#5e97b6',
  hotPink: '#ff66b4',
  kellyGreen: '#5fd800',
  permanentGeraniumLake: '#eb2e15',
  smokyBlack: '#060d1e',
  eerieBlack: '#121824',
  successOpaque: '#1BE3AD',
  iconCTA: '#767F95',
  iconCTAHover: '#B6BDD0',
  unavailableYellow: '#FFCC1B',
  wakaiwaGrey: '#5C6D96',
  borderLight: '#5C6D9680',
  borderFaint: '#5C6D9633',
  borderStrong: '#5C6D964D',
  successTransparent: '#1BE3AD26',
  errorTransparent: '#E3571B33',
  activeNavMenuItem: '7696E00D',
  magenta: '#CD3BE626',
  borderFaint005: '#5C6D960D',
  raisinBlack: '#1F2634',
  blueShades: 'rgba(6, 13, 30, 0.80)',
  blackKnight: '#030814',
  lightCyan: '#E3F9FF',
  spaceCadet: '#1C2841',
  midnightGreen: '#004155',
  orangeRed: '#FE6A2B',
  cyanGlow: '#3BD7E6',
  babyBlue: '#6AA2B6',
  blueModalBG: '#01091D',
  headerSectionGlowBG: '#CD3BE6',
  pinkStar: '#DEABFF',
}

const palette = {
  ...basePalette,
  wakaiwaGrey80: alpha(basePalette.wakaiwaGrey, 0.8),
  wakaiwaGrey50: alpha(basePalette.wakaiwaGrey, 0.5),
  wakaiwaGrey20: alpha(basePalette.wakaiwaGrey, 0.2),
  wakaiwaGrey05: alpha(basePalette.wakaiwaGrey, 0.05),
  aquamarine8: alpha(basePalette.aquamarine, 0.08),
  aquamarine15: alpha(basePalette.aquamarine, 0.15),
  ceruleanFrost15: alpha(basePalette.ceruleanFrost, 0.15),
  hotPink10: alpha(basePalette.hotPink, 0.1),
  lavenderBlue5: alpha(basePalette.lavenderBlue, 0.05),
  kellyGreen6: alpha(basePalette.kellyGreen, 0.06),
  permanentGeraniumLake6: alpha(basePalette.permanentGeraniumLake, 0.06),
  smokyBlack80: alpha(basePalette.smokyBlack, 0.8),
  smokyBlack60: alpha(basePalette.smokyBlack, 0.6),
  eerieBlack80: alpha(basePalette.eerieBlack, 0.8),
  white90: alpha(basePalette.white, 0.9),
  white40: alpha(basePalette.white, 0.4),
  white30: alpha(basePalette.white, 0.3),
  white70: alpha(basePalette.white, 0.7),
  white50: alpha(basePalette.white, 0.5),
  white60: alpha(basePalette.white, 0.6),
  white25: alpha(basePalette.white, 0.25),
  white17: alpha(basePalette.white, 0.17),
  white15: alpha(basePalette.white, 0.15),
  white12: alpha(basePalette.white, 0.12),
  white10: alpha(basePalette.white, 0.1),
  white8: alpha(basePalette.white, 0.08),
  white6: alpha(basePalette.white, 0.06),
  white5: alpha(basePalette.white, 0.05),
  white4: alpha(basePalette.white, 0.04),
  black90: alpha(basePalette.black, 0.9),
  black70: alpha(basePalette.black, 0.7),
  black60: alpha(basePalette.black, 0.6),
  black50: alpha(basePalette.black, 0.5),
  black25: alpha(basePalette.black, 0.25),
  black30: alpha(basePalette.black, 0.3),
  black10: alpha(basePalette.black, 0.1),
  black6: alpha(basePalette.black, 0.06),
  black5: alpha(basePalette.black, 0.05),
  black4: alpha(basePalette.black, 0.04),
  greenApple50: alpha(basePalette.greenApple, 0.5),
  greenApple25: alpha(basePalette.greenApple, 0.25),
  greenApple15: alpha(basePalette.greenApple, 0.15),
  greenApple10: alpha(basePalette.greenApple, 0.1),
  banana10: alpha(basePalette.banana, 0.1),
  banana15: alpha(basePalette.banana, 0.15),
  banana25: alpha(basePalette.banana, 0.25),
  banana50: alpha(basePalette.banana, 0.5),
  banana60: alpha(basePalette.banana, 0.6),
  banana70: alpha(basePalette.banana, 0.7),
  banana80: alpha(basePalette.banana, 0.8),
  berryBlue15: alpha(basePalette.berryBlue, 0.15),
  darkItemBG50: alpha(basePalette.darkItemBG, 0.5),
  pomegranate50: alpha(basePalette.pomegranate, 0.5),
  pomegranate25: alpha(basePalette.pomegranate, 0.25),
  pomegranate15: alpha(basePalette.pomegranate, 0.15),
  pomegranate10: alpha(basePalette.pomegranate, 0.1),
  plum50: alpha(basePalette.plum, 0.5),
  plum25: alpha(basePalette.plum, 0.25),
  plum10: alpha(basePalette.plum, 0.1),
  black15: alpha(basePalette.black, 0.15),
  blue90: alpha(basePalette.blue, 0.95),
  blue80: alpha(basePalette.blue, 0.8),
  blue70: alpha(basePalette.blue, 0.7),
  blue60: alpha(basePalette.blue, 0.6),
  blue40: alpha(basePalette.blue, 0.4),
  cyan90: alpha(basePalette.cyan800, 0.9),
  cyan70: alpha(basePalette.cyan800, 0.7),
  cyan60: alpha(basePalette.cyan800, 0.6),
  cyan40: alpha(basePalette.cyan800, 0.4),
  cyan30: alpha(basePalette.cyan800, 0.3),
  cyan20: alpha(basePalette.cyan800, 0.2),
  cyan10: alpha(basePalette.cyan800, 0.1),
  turquoise20: alpha(basePalette.turquoise, 0.2),
  lightSkyBlue15: alpha(basePalette.lightSkyBlue, 0.15),
  lightSkyBlue20: alpha(basePalette.lightSkyBlue, 0.2),
  lightSkyBlue10: alpha(basePalette.lightSkyBlue, 0.1),
  lightSkyBlue5: alpha(basePalette.lightSkyBlue, 0.05),
  blueYonder80: alpha(basePalette.blueYonder, 0.8),
  blueYonder40: alpha(basePalette.blueYonder, 0.4),
  blueYonder30: alpha(basePalette.blueYonder, 0.3),
  blueYonder20: alpha(basePalette.blueYonder, 0.2),
  blueYonder15: alpha(basePalette.blueYonder, 0.15),
  blueYonder50: alpha(basePalette.blueYonder, 0.5),
  blueYonder10: alpha(basePalette.blueYonder, 0.1),
  blueYonder5: alpha(basePalette.blueYonder, 0.05),
  blueYonder4: alpha(basePalette.blueYonder, 0.04),
  lightSteelBlue10: alpha(basePalette.lightSteelBlue, 0.1),
  littleBoyBlue10: alpha(basePalette.littleBoyBlue, 0.1),
  littleBoyBlue6: alpha(basePalette.littleBoyBlue, 0.06),
  littleBoyBlue5: alpha(basePalette.littleBoyBlue, 0.05),
  aliceBlue70: alpha(basePalette.aliceBlue, 0.7),
  aliceBlue60: alpha(basePalette.aliceBlue, 0.6),
  aliceBlue50: alpha(basePalette.aliceBlue, 0.5),
  aliceBlue40: alpha(basePalette.aliceBlue, 0.4),
  aliceBlue30: alpha(basePalette.aliceBlue, 0.3),
  aliceBlue15: alpha(basePalette.aliceBlue, 0.15),
  aliceBlue10: alpha(basePalette.aliceBlue, 0.1),
  primaryHoverBG: alpha(basePalette.greenApple, 0.8),
  successOpaque10: alpha(basePalette.successOpaque, 0.1),
  errorOpaque10: alpha(basePalette.errorOpaque, 0.1),
  lightCyan50: alpha(basePalette.lightCyan, 0.5),
  orangeRed10: alpha(basePalette.orangeRed, 0.1),
}
export default palette
