import { ThemeUIStyleObject } from 'theme-ui'

export const customStyles = (isDropdown: boolean): ThemeUIStyleObject => {
  const background = isDropdown ? 'raisinBlack' : 'blue'

  return {
    '.react-datepicker': {
      fontFamily: 'Plus Jakarta Sans Variable, sans-serif',
      display: 'flex',
      width: '100%',
      backgroundColor: background,
      border: 'none',
      borderRadius: isDropdown ? '0.8rem' : '0.8rem 0.8rem 0 0',
    },
    '.react-datepicker__navigation': {
      top: 0,
      '&:hover': {
        '.react-datepicker__navigation-icon::before': {
          borderColor: 'aliceBlue',
        },
      },
      '.react-datepicker__navigation-icon::before': {
        borderColor: 'aliceBlue30',
      },
    },
    '.react-datepicker__day--keyboard-selected': {
      backgroundColor: 'transparent',
      border: '1px solid',
      borderRadius: '0.8rem',
      borderColor: 'plumpPurple',
    },
    '.react-datepicker__header': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '6rem',
      backgroundColor: background,
      borderBottomColor: 'blueYonder40',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px !important',
      paddingTop: 0,
      '.react-datepicker__current-month': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'aliceBlue',
        height: '3.2rem',
        fontSize: '1.2rem',
      },
      '.react-datepicker__day-names': {
        display: 'flex',
        height: '2.4rem',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '1px solid',
        borderColor: 'blueYonder40',
        // borderBottom: '1px solid',
        '& > div': {
          display: 'flex',
          flex: 1,
          color: 'aliceBlue60',
          fontSize: '1.2rem',
          justifyContent: 'center',
        },
      },
    },
    '.react-datepicker__week': {
      display: 'flex',
      '& > div:not(disabled)': {
        border: '1px solid',
        borderColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '5.7rem',
        height: '3.2rem',
        fontSize: '1.2rem',
        color: 'aliceBlue60',
        '&:hover': {
          border: '1px solid',
          borderColor: 'plumpPurple',
          backgroundColor: 'transparent',
          borderRadius: '0.8rem',
          color: 'aliceBlue',
        },
      },
      '.react-datepicker__day--today': {
        borderColor: 'plumpPurple',
        borderRadius: '0.8rem',
      },
      '.react-datepicker__day--selected': {
        borderColor: 'plumpPurple',
        backgroundColor: 'plumpPurple',
        color: 'text',
      },
      '.react-datepicker__day--disabled': {
        color: 'aliceBlue30',
        hover: {
          borderColor: 'transparent',
        },
      },
      '.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selected)': {
        backgroundColor: 'plumpPurple',
        color: 'text',
        opacity: 0.5,
      },
      '.react-datepicker__day--in-range': {
        backgroundColor: 'plumpPurple',
        color: 'text',
      },
    },

    '.react-datepicker__input-time-container .react-datepicker-time__input-container': {
      display: 'flex',
      width: '100%',
      backgroundColor: background,
      justifyContent: 'center',
      borderTop: '1px solid',
      borderTopColor: 'blueYonder40',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    '.react-datepicker__input-time-container': {
      display: 'flex',
      width: '100%',
      margin: 0,
    },
    '.react-datepicker__month-container': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    '.react-datepicker__month': {
      backgroundColor: background,
      m: 0,
    },
    '.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input':
      {
        display: 'flex',
        width: '100%',
        margin: 0,
      },
    '.react-datepicker__day--selected': {
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: 'littleBoyBlue',
      borderRadius: '0.8rem',
      '&:hover': {
        backgroundColor: 'transparent',
        borderRadius: '0.8rem',
        color: 'aliceBlue',
      },
    },
  }
}

export const inputStyles = (isDropdown?: boolean): ThemeUIStyleObject => {
  return {
    display: 'flex',
    maxWidth: '6.8rem !important',
    height: '2.5rem',
    fontSize: '1.2rem',
    border: 'solid 1px',
    borderColor: 'transparent',
    px: '2.4rem',
    py: 0,
    borderRadius: 0,
    color: 'aliceBlue60',
    backgroundColor: isDropdown ? 'raisinBlack' : 'blue',
  }
}
