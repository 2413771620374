import React, { ElementType, PropsWithChildren, ReactElement } from 'react'
import ClientsIcon from 'images/dashboard/devices/clients.svg'
import { navigate } from 'gatsby'
import { Flex } from '@theme-ui/components'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import IpsIcon from 'images/dashboard/devices/ips.svg'
import { setSelectedDeviceId } from 'store/access'
import AnalyticsIcon from 'images/dashboard/devices/analytics.svg'
import Tippy from '@tippyjs/react'
import { Button, Svg } from 'ui'
import VerticalDivider from 'ui/VerticalDivider'
import useQueryString from 'utils/useQueryString'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { useDeviceClientsData } from 'components/Dashboard/Analytics/DeviceClients/useDeviceClientsData'
import getFormattedNumber from 'utils/getFormattedNumber'
import { DeviceInfo } from 'store/api/devices/devices.interface'
import { useAppDispatch } from 'store/hooks'
import useGetColorMode from 'utils/useGetColorMode'

export default function DeviceInfoButtons({
  device,
  devicesWithParentDevice,
  isSmallTablet,
}: {
  device: DeviceInfo
  devicesWithParentDevice?: DeviceInfo[]
  isSmallTablet?: boolean
}): ReactElement {
  const dispatch = useAppDispatch()
  const { qs } = useQueryString()
  const { relocate } = useQueryString()
  const isMobile = useBreakpointIndex() === 0
  const { setSelectedDeviceId: setAnalyticsDeviceId } = useDeviceClientsData()

  const customClients = device?.clients?.filter(client =>
    devicesWithParentDevice?.some(device => device.parent_device?.client_id === client.id),
  )

  const genericClients = device?.clients?.filter(client =>
    customClients?.every(specialClient => specialClient.id !== client.id),
  )

  const formattedClients = getFormattedNumber(genericClients?.length || 0)
  const formattedCustomClients = getFormattedNumber(customClients?.length || 0)
  const formattedIps = getFormattedNumber(device.ip_count || 0)

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <DeviceInfoButtonWithDivider
        ariaLabel="open clients page"
        tooltipText="Clients"
        svg={ClientsIcon}
        testId="clients-button"
        onClick={() =>
          navigate(
            `/dashboard/endpoints/${device.device_id}/clients${
              qs.orgId ? `?orgId=${qs.orgId}` : ''
            }`,
          )
        }
      >
        <Flex sx={{ flexDirection: 'column' }}>
          {!!customClients?.length && (
            <TextWithOverFlowAndTippyPopup
              variant="size12Weight700Letter018"
              content={formattedCustomClients}
              ariaLabel={`${customClients?.length || 0}`}
              tippyContent={`${customClients?.length || 0}`}
              isEnabled={+formattedCustomClients !== (customClients?.length || 0)}
              shouldCheckOnOverflow={false}
              sxText={{ color: 'banana60' }}
              className="device-custom-clients"
              placement="bottom"
            />
          )}
          <TextWithOverFlowAndTippyPopup
            variant="size12Weight700Letter018"
            content={formattedClients}
            ariaLabel={`${genericClients?.length || 0}`}
            tippyContent={`${genericClients?.length || 0}`}
            isEnabled={+formattedClients !== (genericClients?.length || 0)}
            shouldCheckOnOverflow={false}
            sxText={{ color: 'aliceBlue60' }}
            placement="bottom"
          />
        </Flex>
      </DeviceInfoButtonWithDivider>
      <DeviceInfoButtonWithDivider
        ariaLabel="open ips page"
        tooltipText="IPs"
        testId="ips-button"
        svg={IpsIcon}
        onClick={() => {
          dispatch(setSelectedDeviceId(device.PK))
          relocate(`/dashboard/ips/${device.PK}`)
        }}
      >
        <TextWithOverFlowAndTippyPopup
          variant="size12Weight700Letter018"
          content={formattedIps}
          tippyContent={`${device.ip_count || 0}`}
          ariaLabel={`${device.ip_count || 0}`}
          isEnabled={+formattedIps !== (device.ip_count || 0)}
          shouldCheckOnOverflow={false}
          sxText={{ color: 'aliceBlue60' }}
          placement="bottom"
        />
      </DeviceInfoButtonWithDivider>
      <DeviceInfoButtonWithDivider
        shouldDisplayVerticalDivider={!isSmallTablet && !isMobile}
        ariaLabel="open statistics page"
        tooltipText="Analytics"
        testId="analytics-button"
        svg={AnalyticsIcon}
        onClick={() => {
          if (device) {
            setAnalyticsDeviceId(device.device_id)
          }
          relocate('/dashboard/statistics')
        }}
      />
    </Flex>
  )
}

function DeviceInfoButtonWithDivider({
  testId,
  ariaLabel,
  svg,
  onClick,
  children,
  tooltipText,
  shouldDisplayVerticalDivider = true,
}: PropsWithChildren<{
  testId: string
  ariaLabel: string
  svg: ElementType
  onClick?: () => void
  tooltipText?: string
  shouldDisplayVerticalDivider?: boolean
}>) {
  const { isLightMode } = useGetColorMode()

  return (
    <>
      <Tippy
        theme={isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
        arrow={false}
        content={tooltipText}
        maxWidth={200}
        offset={[0, 4]}
      >
        <Button
          data-testid={testId}
          ariaLabel={ariaLabel}
          variant="simple"
          sx={{
            gap: '0.4rem',
            p: [0, '0.4rem'],
            '&:hover:not([disabled]) .device-custom-clients': { color: 'banana' },
          }}
          onClick={onClick}
        >
          <Svg svg={svg} fill={'aliceBlue60'} />
          {children}
        </Button>
      </Tippy>
      {shouldDisplayVerticalDivider && (
        <VerticalDivider
          sx={{
            mx: '0.8rem',
            flexShrink: 0,
            height: '2.4rem',
            color: 'blueYonder15',
            backgroundColor: 'blueYonder15',
          }}
        />
      )}
    </>
  )
}
