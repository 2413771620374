import React, { ReactElement, useEffect } from 'react'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import LegacyIPDataRow from 'components/SetupGuide/ResolverTabs/Components/UserResolver/LegacyIPDataRow'
import { Flex, Text } from 'theme-ui'
import { Svg, ExternalLink } from 'ui'
import useQueryString from 'utils/useQueryString'
import { useLocation } from '@reach/router'
import { checkCurrentIp } from 'store/defaultRule'
import { HighlightProtocolInSetupGuide } from 'store/api/devices/devices.interface'
import SmallInfoIcon from 'images/dashboard/info-icon-small.svg'

export interface LegacyResolverData {
  ipv4Primary?: string
  ipv4Secondary?: string
  ipv6Primary?: string
  ipv6Secondary?: string
}
export default function LegacyResolver({
  highlight,
  legacyResolverData,
}: {
  highlight?: HighlightProtocolInSetupGuide[]
  legacyResolverData: LegacyResolverData
}): ReactElement {
  const { qs } = useQueryString()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const setupOs = qs.setupOs
  const isFreeDNS = location.pathname.includes('free-dns')

  const currentIPTypeDetails = useAppSelector(s => s.defaultRule.currentIPTypeDetails)

  const isIPv6Network =
    !!currentIPTypeDetails?.isFulfilled &&
    !!currentIPTypeDetails?.ipv6?.type &&
    !!currentIPTypeDetails?.ipv6?.ip

  const v6Highlighted = highlight?.includes(HighlightProtocolInSetupGuide.IPv6) && !!isIPv6Network
  const v4Highlighted =
    highlight?.includes(HighlightProtocolInSetupGuide.IPv4) &&
    (!isIPv6Network || !highlight?.includes(HighlightProtocolInSetupGuide.IPv6))

  useEffect(() => {
    dispatch(checkCurrentIp())
  }, [dispatch])

  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Flex
        sx={{
          flexShrink: 0,
          gap: '0.8rem',
          px: '2.4rem',
          mt: '1.2rem',
        }}
      >
        <Flex
          sx={{
            width: '50%',
            flexDirection: 'column',
            color: v4Highlighted ? 'greenApple' : 'white',
            borderColor: 'greenApple',
            borderBottom: v4Highlighted ? '1px solid' : 'none',
          }}
          data-testid="ipv4"
        >
          <LegacyIPDataRow
            title="IPv4"
            primary={legacyResolverData.ipv4Primary}
            secondary={legacyResolverData.ipv4Secondary}
            isHighlighted={v4Highlighted}
          />
        </Flex>
        <Flex
          sx={{
            width: '50%',
            flexDirection: 'column',
            color: v6Highlighted ? 'greenApple' : 'aliceBlue',
          }}
          data-testid="ipv6"
        >
          <LegacyIPDataRow
            title="IPv6"
            primary={legacyResolverData.ipv6Primary}
            secondary={legacyResolverData.ipv6Secondary}
            isHighlighted={v6Highlighted}
          />
        </Flex>
      </Flex>
      {setupOs && !isFreeDNS && (
        <Flex
          sx={{
            my: '1.2rem',
            gap: '1.6rem',
            mx: '2.4rem',
            border: '1px solid',
            borderColor: 'borderStrong',
            borderRadius: '0.8rem',
          }}
        >
          <Flex sx={{ flex: 1, m: '0.8rem 1.2rem', alignItems: 'center', gap: '1.2rem' }}>
            <Svg fill="aliceBlue60" svg={SmallInfoIcon} />
            <Text variant="size12Weight400Line138Letter048" sx={{ color: 'aliceBlue60' }}>
              Legacy DNS requires your source IP to be updated at all times, otherwise your rules
              will not be enforced.&ensp;
              <ExternalLink
                to={`${DOCS_CONTROL_D_DOMAIN}/docs/ip-not-authorized`}
                sx={{
                  color: 'aliceBlue60',
                  textDecoration: 'underline',
                  ':hover': {
                    color: 'aliceBlue',
                  },
                }}
              >
                Learn more
              </ExternalLink>
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
