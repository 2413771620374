import React, { useMemo, useEffect, ReactElement } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import ResolverTabContent from 'components/SetupGuide/ResolverTabs/Components/UserResolver/ResolverTabContent'
import useResolverTabHighlight from 'components/SetupGuide/ResolverTabs/Components/useResolverTabHighlight'
import { LegacyResolver, ResolverTabData } from 'components/SetupGuide/ResolverTabs/ResolverTabs'
import { checkCurrentIp } from 'store/defaultRule'
import isEmpty from 'lodash/isEmpty'
import useQueryString from 'utils/useQueryString'
import { useGetDevicesQuery } from 'store/api/devices'

export default function UserResolverTabs(): ReactElement {
  const dispatch = useAppDispatch()
  const { qs } = useQueryString()
  const selectedDevicePk = qs.deviceId
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: devicesData } = useGetDevicesQuery('', { skip: !sessionToken })
  const selectedDevice = devicesData?.devices?.find(d => d.PK === selectedDevicePk)
  const { highlight } = useResolverTabHighlight(selectedDevice?.icon ?? qs.setupOs)
  const currentIPTypeDetails = useAppSelector(s => s.defaultRule.currentIPTypeDetails)

  useEffect(() => {
    if (qs.helpPane && isEmpty(currentIPTypeDetails)) {
      dispatch(checkCurrentIp())
    }
  }, [currentIPTypeDetails, dispatch, qs.helpPane])

  const resolverTabData: ResolverTabData = useMemo(() => {
    //selected device specific resolver
    const deviceResolvers = devicesData?.devices?.find(
      f => f.PK.toString() === selectedDevicePk,
    )?.resolvers
    return {
      ...(deviceResolvers?.v4
        ? {
            legacy: {
              ipv4Primary: deviceResolvers?.v4?.[LegacyResolver.PRIMARY],
              ipv4Secondary: deviceResolvers?.v4?.[LegacyResolver.SECONDARY],
              ipv6Primary: deviceResolvers?.v6?.[LegacyResolver.PRIMARY],
              ipv6Secondary: deviceResolvers?.v6?.[LegacyResolver.SECONDARY],
            },
          }
        : {}),
      secure: {
        resolverId: deviceResolvers?.uid,
        doh: deviceResolvers?.doh,
        dot: deviceResolvers?.dot,
      },
    }
  }, [devicesData?.devices, selectedDevicePk])
  return (
    <ResolverTabContent
      {...{
        resolverTabData,
        highlight,
      }}
    />
  )
}
