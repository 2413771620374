import React, { ReactElement, useEffect, useState } from 'react'
import { Text, Flex } from 'theme-ui'
import ruleTypes from 'components/Dashboard/Profiles/CustomRules/ruleTypes'
import RuleActions from 'components/Dashboard/Profiles/RuleActions'
import { RuleType } from 'store/api/rules'
import { updateCustomRuleDraft, updateGroupDraft } from 'store/customRules'
import ChooseDestinationButton from 'components/Dashboard/Profiles/CustomRules/RuleOrFolderDialog/ChooseDestination'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ActionableRule, isCustomRule, isGroupRule } from 'utils/uniteRules'
import InfoIcon from 'images/small-info-icon.svg'
import ExpiryIcon from 'images/customRules/expiry-icon.svg'
import { setRuleTooltipSettings } from 'store/tutorial/tutorial'
import IconWithTippyPopup from 'ui/IconWithTippyPopup'
import { useGetProxiesQuery } from 'store/api/proxies'
import { useGetUserQuery } from 'store/api/user'
import { Button, Svg } from 'ui'
import { FutureDateTimePicker } from 'ui/DateTimePicker'

interface RuleTypeMenuProps {
  rule: ActionableRule
  forceRuleType?: RuleType
}
export default function RuleTypeMenu({ rule, forceRuleType }: RuleTypeMenuProps): ReactElement {
  const dispatch = useAppDispatch()
  const { data: proxiesData } = useGetProxiesQuery('')
  const proxyLocations = proxiesData?.proxies
  const proxyLocationCodes = proxyLocations?.map(p => p.PK)
  const { data: userData } = useGetUserQuery('')
  const userPk = userData?.PK || ''
  const ruleTypeInfo = ruleTypes.find(rt => rt.ruleType === (forceRuleType ?? rule?.action?.do))
  const isBlocked = (forceRuleType ?? rule?.action?.do) === RuleType.BLOCK
  const draftRule = useAppSelector(s => s.ruleTray.draftCustomRule)

  const handleRuleTypeClick = (ruleType: RuleType): void => {
    dispatch(setRuleTooltipSettings({ userPk, selectedRuleType: ruleType }))
    // fix for #1369. Need to check via and change from Spoof_Tag to Spoof_IP
    if (
      rule.action?.via &&
      rule.action?.via !== '?' &&
      !proxyLocationCodes?.includes(rule.action?.via ?? '') &&
      ruleType === RuleType.SPOOF_TAG
    ) {
      ruleType = RuleType.SPOOF_IP
    }
    if (rule.action?.do !== ruleType) {
      if (isCustomRule(rule)) {
        dispatch(updateCustomRuleDraft({ action: { do: forceRuleType || ruleType } })) // let the via remain as is, so that user gets it back in case redirect is selected again
      }
      if (isGroupRule(rule)) {
        dispatch(updateGroupDraft({ action: { do: ruleType } }))
      }
    }
  }
  const [shouldShowExpiryDate, setShouldShowExpiryDate] = useState(false)

  useEffect(() => {
    setShouldShowExpiryDate(!!draftRule.action?.ttl && draftRule.action?.ttl !== -1)
  }, [draftRule.action?.ttl])

  return (
    <Flex
      data-testid={isCustomRule(rule) ? 'custom-rule-type-menu' : 'folder-rule-type-menu'}
      sx={{
        flexDirection: 'column',
        px: '1.6rem',
        py: '1.2rem',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Flex sx={{ flexDirection: 'column' }}>
            <Flex sx={{ flexDirection: 'row' }}>
              <Text
                variant="size15Weight700"
                data-testid="selected-rule-type-text"
                sx={{ color: isBlocked ? 'errorOpaque' : 'greenApple' }}
              >
                {ruleTypeInfo?.text ?? ''}
              </Text>
              {forceRuleType !== undefined && (
                <IconWithTippyPopup
                  content={`Since you're currently adding a rule inside an Action Folder, the rule will be saved using the folder's current action.  If you want to make the rule do something different, please add it to a different folder.`}
                  ariaLabel={`Since you're currently adding a rule inside an Action Folder, the rule will be saved using the folder's current action.  If you want to make the rule do something different, please add it to a different folder.`}
                  className="info-icon"
                  sx={{ width: '1.2rem', height: '1.2rem', ml: '0.5rem' }}
                  svg={InfoIcon}
                  fill="white50"
                />
              )}
            </Flex>
            {ruleTypeInfo && (
              <Text
                variant="size12Weight400"
                sx={{
                  color: 'aliceBlue60',
                }}
              >
                {ruleTypeInfo.description}
              </Text>
            )}
          </Flex>

          <RuleActions
            rule={rule}
            handleRuleTypeClick={handleRuleTypeClick}
            forceRuleType={forceRuleType}
          />
        </Flex>

        {!isGroupRule(rule) && (
          <Flex
            sx={{
              ml: '1.2rem',
              pl: '1.2rem',
              borderLeft: '1px solid',
              borderLeftColor: 'blueYonder15',
            }}
          >
            <Button
              data-testid="rule-expiry-button"
              ariaLabel="rule expiry button"
              variant="simple"
              sx={{
                p: 0,
                'svg > g': {
                  opacity: shouldShowExpiryDate ? 1 : 0.6,
                },
                ':hover': {
                  'svg > g': {
                    opacity: 1,
                  },
                },
              }}
              onClick={(): void => {
                dispatch(
                  updateCustomRuleDraft({
                    action: {
                      ttl: shouldShowExpiryDate ? -1 : Math.floor(new Date().getTime() / 1000),
                    },
                  }),
                )
                setShouldShowExpiryDate(prev => !prev)
              }}
            >
              <Svg svg={ExpiryIcon} fill="aliceBlue" />
            </Button>
          </Flex>
        )}
      </Flex>

      <ChooseDestinationButton rule={rule} forceRuleType={forceRuleType} />

      {shouldShowExpiryDate && (
        <FutureDateTimePicker
          date={
            draftRule.action?.ttl &&
            new Date(draftRule.action?.ttl * 1000).getMonth() === new Date().getMonth()
              ? new Date(draftRule.action?.ttl * 1000)
              : null
          }
          onDateChange={date => {
            dispatch(updateCustomRuleDraft({ action: { ttl: Math.floor(date.getTime() / 1000) } }))
          }}
          minDate={new Date()}
          maxDate={new Date('2200-12-31')}
        />
      )}
    </Flex>
  )
}
