const sharedStyles = {
  cursor: 'pointer',
  fontSize: '2.1rem',
  borderRadius: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'body',
  ':disabled': {
    cursor: 'auto',
    backgroundColor: 'white10',
    color: 'aliceBlue60',
    border: '1px solid rgba(229,229,229,0)',
    '& svg': {
      fill: 'aliceBlue60',
    },
  },
}

const roundedSharedStyles = {
  cursor: 'pointer',
  outline: 'none',
  py: '0.9rem',
  px: '2rem',
  borderRadius: '10rem',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: '1.7rem',
  color: 'white',
  fontFamily: 'body',
  ':disabled': {
    cursor: 'auto',
    backgroundColor: 'blueYonder10',
    color: 'aliceBlue30',
    border: '1px solid',
    borderColor: 'blueYonder15',
    boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.30)',
  },
}

const sharedNewStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  minWidth: '6rem',
  minHeight: '3.2rem',
  px: '1.6rem',
  py: 0,
  borderRadius: '2rem',
  border: '1px solid',
  fontSize: '1.5rem',
  fontWeight: 700,
  outline: 'none',
  boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.30)',
  fontFamily: 'body',
  ':disabled': {
    cursor: 'auto',
    borderColor: 'blueYonder15',
    backgroundColor: 'blueYonder10',
    color: 'aliceBlue30',
    span: {
      color: 'aliceBlue30',
    },
    'svg path': {
      fill: 'aliceBlue30',
    },
  },
}

export default {
  // old styles can be removed when they are no longer needed
  primary: {
    ...sharedStyles,
    color: 'black',
    ':hover': {
      ':not([disabled])': {
        color: 'white',
        span: {
          color: 'white',
        },
        backgroundColor: 'primaryHoverBG',
        'svg path': {
          fill: 'white',
        },
        transform: 'scale(1.025)',
      },
    },
  },
  primaryWithoutScaling: {
    ...sharedStyles,
    color: 'black',
    ':hover': {
      ':not([disabled])': {
        color: 'white',
        backgroundColor: 'primaryHoverBG',
        'svg path': {
          fill: 'white',
        },
      },
    },
  },
  secondary: {
    ...sharedStyles,
    backgroundColor: 'plum',
    color: 'white',
    '&:hover': {
      ':not([disabled])': {
        color: 'white',
        backgroundColor: 'secondaryHoverBG',
        '& svg path': {
          fill: 'white',
        },
        transform: 'scale(1.025)',
      },
    },
  },
  secondaryWithoutScaling: {
    ...sharedStyles,
    backgroundColor: 'plum',
    color: 'text',
    '& svg path': {
      fill: 'text',
    },
    '&:hover': {
      ':not([disabled])': {
        color: 'text',
        backgroundColor: 'secondaryHoverBG',
        '& svg path': {
          fill: 'text',
        },
      },
    },
  },
  tertiary: {
    ...sharedStyles,
    backgroundColor: 'transparent',
    borderRadius: '59px',
    border: '2px solid',
    borderColor: 'plum50',
    '&:hover': {
      ':not([disabled])': {
        color: 'white',
        backgroundColor: 'plum25',
        '& svg path': {
          fill: 'white',
        },
      },
    },
  },
  bordered: {
    ...sharedStyles,
    backgroundColor: 'transparent',
    borderRadius: '59px',
    border: '1px solid',
    borderColor: 'plum',
    '&:hover': {
      ':not([disabled])': {
        color: 'white',
        backgroundColor: 'secondaryHoverBG',
        '& svg path': {
          fill: 'white',
        },
      },
    },
  },
  borderedGreen: {
    ...sharedStyles,
    backgroundColor: 'transparent',
    borderRadius: '59px',
    border: '1px solid',
    borderColor: 'greenApple15',
    '&:hover': {
      ':not([disabled])': {
        color: 'white',
        backgroundColor: 'greenApple25',
        '& svg path': {
          fill: 'white',
        },
      },
    },
  },
  simple: {
    ...sharedStyles,
    backgroundColor: 'transparent',
    outline: 'none',
    // hover, but not a tap on touch screen
    '@media (hover: hover)': {
      '&:hover': {
        ':not([disabled])': {
          color: 'aliceBlue',
          span: {
            color: 'aliceBlue',
          },
          '& svg path': {
            fill: 'aliceBlue',
          },
          '& svg g': {
            opacity: 1,
          },
          '& > div span': {
            color: 'aliceBlue',
          },
        },
      },
    },
  },
  transparent: {
    ...sharedStyles,
    backgroundColor: 'transparent',
    outline: 'none',
  },
  icon: {
    ...sharedStyles,
    margin: 0,
    padding: 0,
  },
  switch: {
    cursor: 'pointer',
    position: 'relative',
    m: 0,
    p: 0,
    height: '2.4rem',
    borderRadius: 1,
    width: '4.8rem',
    minWidth: '4.8rem',
    '&:disabled': {
      backgroundColor: 'grayLogoBG',
      border: '1px solid',
      borderColor: 'white10',
      '.switch-toggle': {
        backgroundColor: 'transparent',
        border: '2px solid',
        borderColor: 'white10',
        boxShadow: '0 2px 0 0 rgba(0, 0, 0, 0.25)',
        '& svg': {
          fill: 'aliceBlue60',
        },
      },
    },
    '& .switch-toggle': {
      boxShadow: '0 0.2rem 0.2rem 0 rgba(0,0,0,0.40)',
      m: 0,
      ml: '0.1rem',
      width: '2.0rem',
      height: '2.0rem',
      borderRadius: 3,
      transitionProperty: 'transform',
      transitionTimingFunction: 'ease-out',
      transitionDuration: '0.1s',
    },
  },
  multiSwitchSVG: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    padding: 0,
    marginRight: '1.6rem',
    '& > span > svg': {
      width: '1.6rem',
      height: '2.4rem',
    },
  },
  roundedSimple: {
    ...roundedSharedStyles,
    color: 'lightSteelBlue',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'wakaiwaGrey20',
    },
  },
  roundedSecondary: {
    ...roundedSharedStyles,
    border: '1px solid',
    borderColor: 'cyan60',
    backgroundColor: 'lightSkyBlue10',
    lineHeight: '138%',
    '&:hover': {
      backgroundColor: 'lightSkyBlue15',
    },
    '&:active': {
      backgroundColor: 'lightSkyBlue5',
    },
  },
  roundedTertiary: {
    ...roundedSharedStyles,
    lineHeight: '138%',
    height: '3.8rem',
    backgroundColor: 'lightSkyBlue10',
    '&:hover': {
      backgroundColor: 'lightSkyBlue20',
    },
    '&:active': {
      backgroundColor: 'lightSkyBlue5',
    },
  },
  gradientButton: {
    fontFamily: 'body',
    height: '4.7rem',
    width: ['100%', 'fit-content'],
    alignItems: 'center',
    py: '1.2rem',
    px: '2rem',
    gap: '0.5rem',
    fontSize: '1.7rem',
    borderRadius: '50px',
    background: 'linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)',
    boxShadow:
      '0px 0px 20px 0px rgba(97, 63, 219, 0.6), 0px 1px 0.2px 0px rgba(255, 255, 255, 0.4) inset',
    '&:hover': {
      ':not([disabled])': {
        cursor: 'pointer',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)',
      },
    },
    '&:active': {
      ':not([disabled])': {
        background:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)',
      },
    },
    ':disabled': {
      cursor: 'auto',
      backgroundColor: 'transparent',
      color: 'white25',
      border: '1px solid',
      borderColor: 'rgba(255, 255, 255, 0.08)',
      borderRadius: '10rem',
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.10) 100%)',
      backgroundClip: 'padding-box',
      boxShadow: 'none',
      '& > span': {
        color: 'white25',
      },
      '& > div span': {
        color: 'white25',
      },
      '& svg path': {
        fill: 'white25',
      },
    },
  },
  // new button styles
  newPrimary: {
    ...sharedNewStyles,
    backgroundColor: 'greenApple',
    borderColor: 'blueYonder15',
    color: 'black',
    span: {
      color: 'black',
    },
    ':hover': {
      ':not([disabled])': {
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #1BE3AD',
      },
    },
  },
  newSecondary: {
    ...sharedNewStyles,
    backgroundColor: 'plumpPurple',
    borderColor: 'white10',
    color: 'text',
    span: {
      color: 'text',
    },
    ':hover': {
      ':not([disabled])': {
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #593EA5',
      },
    },
  },
  newTertiary: {
    ...sharedNewStyles,
    backgroundColor: 'darkJungleGreen',
    borderColor: 'cyan60',
    color: 'aliceBlue',
    span: {
      color: 'aliceBlue',
    },
    ':hover': {
      ':not([disabled])': {
        background: ({ colors }): string =>
          `linear-gradient(0deg, rgba(118, 219, 255, 0.15) 0%, rgba(118, 219, 255, 0.15) 100%), ${colors.darkJungleGreen}`,
      },
    },
  },
  action: {
    ...sharedNewStyles,
    backgroundColor: 'white6',
    color: 'aliceBlue60',
    px: '1.8rem',
    border: 'none',
    borderRadius: '10rem',
    justifyContent: 'center',
    span: {
      color: 'aliceBlue60',
    },
    '@media (hover: hover)': {
      '&:hover': {
        ':not([disabled])': {
          backgroundColor: 'white8',
          color: 'aliceBlue',
          span: {
            color: 'aliceBlue',
          },
          'svg path': {
            fill: 'aliceBlue',
          },
        },
      },
    },
  },
  barryChatAction: {
    fontFamily: 'body',
    width: '100%',
    height: '48px',
    flexShrink: 0,
    backgroundColor: 'darkJungleGreen',
    padding: ['16px', '14px'],
    zIndex: 'zIndex2',
    display: 'flex',
    borderRadius: 0,
    justifyContent: 'center',
    alignItems: 'flex-end',
    fontSize: '14px',
    fontWeight: 400,
    color: 'aliceBlue',
    WebkitTapHighlightColor: 'transparent',
    '&:hover': {
      ':not([disabled])': {
        cursor: 'pointer',
      },
    },
  },
  barryChatSubmit: {
    minWidth: '4rem',
    minHeight: '4rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    padding: 0,
    margin: 0,
    WebkitTapHighlightColor: 'transparent',
    display: 'flex',
  },
}
