import { baseApi, getQueryArgs, throwErrorFromResponseData, transformErrorResponse } from '../index'
import {
  ImportJsonBody,
  ModifyProfile,
  NewProfile,
  Profile,
  ProfileJsonResponse,
  ProfileOptionsRespone,
  ProfileResponse,
  PutProfileOptionArgs,
  PutProfileOptionResponse,
} from './profiles.interface'
import { EnabledStatus } from 'store/api/rules'
import sortBy from 'lodash/sortBy'
import { ApiResponse, EmptyBodyResponse } from '../http'
import { filtersApi } from 'store/api/filters/filters'

/**
 * This function converts the PK returned by the api to a string
 */
const scrubProfiles = (profiles: Profile[]): Profile[] =>
  profiles?.map(profile => ({ ...profile, PK: profile?.PK.toString() }))

export const profilesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProfiles: builder.query({
      query: () => getQueryArgs(`/profiles`),
      transformErrorResponse,
      transformResponse: (response: ProfileResponse) => response.body,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            profilesApi.util.updateQueryData('getProfiles', '', draft => {
              data.profiles = scrubProfiles(data.profiles)
              draft.profiles = data.profiles
              draft.shared_profiles = data.shared_profiles
            }),
          )
        } catch {}
      },
      providesTags: ['Profiles'],
    }),
    putProfile: builder.mutation({
      query: ({ pk, body }: { pk: string; body: Partial<ModifyProfile>; editProfileId?: string }) =>
        getQueryArgs(`/profiles/${pk}`, 'PUT', body),
      transformErrorResponse,
      transformResponse: (response: ProfileResponse) => response.body,
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            profilesApi.util.updateQueryData('getProfiles', '', draft => {
              const scrubbedProfiles = scrubProfiles(data.profiles)
              if (data.shared_profiles) {
                draft.shared_profiles = data.shared_profiles
              }

              const index = draft.profiles.findIndex(d => d.PK === scrubbedProfiles[0].PK)
              draft.profiles[index] = scrubbedProfiles[0]
            }),
          )
        } catch {}
      },
    }),
    postProfile: builder.mutation({
      query: (body: NewProfile) => getQueryArgs('/profiles', 'POST', body),
      transformErrorResponse,
      transformResponse: (response: ProfileResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            profilesApi.util.updateQueryData('getProfiles', '', draft => {
              draft.profiles = sortBy(
                [...draft.profiles, ...scrubProfiles(data.profiles)],
                profile => `${profile.name}`,
              )
              if (data.shared_profiles) {
                draft.shared_profiles = data.shared_profiles
              }
            }),
          )
        } catch {}
      },
    }),
    deleteProfile: builder.mutation({
      query: ({ pk }: { pk: string }) => getQueryArgs(`/profiles/${pk}`, 'DELETE'),
      transformErrorResponse,
      transformResponse: (response: EmptyBodyResponse) => {
        throwErrorFromResponseData(response)

        return {
          message: response.message,
        }
      },
      async onQueryStarted({ pk }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            profilesApi.util.updateQueryData('getProfiles', '', draft => {
              draft.profiles = draft.profiles.filter(d => d.PK !== pk.toString())
            }),
          )
        } catch {}
      },
    }),
    getAllProfileOptions: builder.query({
      query: () => getQueryArgs('/profiles/options'),
      transformErrorResponse,
      transformResponse: (response: ProfileOptionsRespone) => {
        response.body.options = response.body.options.map(o => ({ ...o, id: o.PK }))
        return response.body
      },
      providesTags: ['ProfileOptions'],
    }),
    getProfileOptions: builder.query({
      query: (profileId: string) => getQueryArgs(`/profiles/${profileId}/options`),
      transformErrorResponse,
      transformResponse: (response: ProfileOptionsRespone) => response.body,
    }),
    putProfileOptions: builder.mutation({
      query: ({ profileId, status, optionId, value, custom_value, cbp }: PutProfileOptionArgs) =>
        getQueryArgs(`/profiles/${profileId}/options/${optionId}`, 'PUT', {
          status,
          value,
          custom_value,
          ...(cbp ?? {}),
        }),
      transformErrorResponse,
      transformResponse: (response: ApiResponse<PutProfileOptionResponse>) => response.body,
      async onQueryStarted(
        { profileId, status, optionId, value, custom_value, cbp },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            profilesApi.util.updateQueryData('getProfiles', '', draft => {
              const currentProfile = draft?.profiles.find(p => p.PK === profileId)

              if (currentProfile?.profile) {
                if (status === EnabledStatus.ENABLED) {
                  // check if it exists
                  const existingOptionIndex = currentProfile?.profile?.opt.data.findIndex(
                    o => o.PK === optionId,
                  )
                  // if it exists, update it, otherwise add it
                  if (existingOptionIndex > -1) {
                    currentProfile.profile.opt.data[existingOptionIndex] = {
                      PK: optionId,
                      value: data.options?.[0].value.toString() ?? value,
                      custom_value,
                      cbp,
                    }
                  } else {
                    currentProfile.profile.opt.count += 1
                    currentProfile.profile.opt.data.push({
                      PK: optionId,
                      value,
                      custom_value,
                      cbp,
                    })
                  }
                } else {
                  // if it's disabled, remove it
                  currentProfile.profile.opt.count -= 1
                  currentProfile.profile.opt.data = currentProfile.profile.opt.data.filter(
                    o => o.PK !== optionId,
                  )
                }
              }
            }),
          )

          dispatch(
            filtersApi.util.updateQueryData('getFilters', { profileId }, draft => {
              draft.filters = draft.filters.map(filter => {
                if (filter.PK === 'malware') {
                  const level = filter.levels?.find(level => level.name === optionId)

                  if (level) {
                    level.status = status

                    if (status === EnabledStatus.ENABLED) {
                      level.opt = data.options
                    } else {
                      level.opt = undefined
                    }
                    if (filter.action) {
                      filter.action.status = filter.levels?.some(
                        l => l.status === EnabledStatus.ENABLED,
                      )
                        ? EnabledStatus.ENABLED
                        : EnabledStatus.DISABLED
                    }
                  }
                }
                return filter
              })
            }),
          )
        } catch {}
      },
    }),
    exportProfile: builder.query({
      query: (profileId: string) => getQueryArgs(`/profiles/${profileId}/export/json`),
      transformErrorResponse,
      transformResponse: (response: ProfileJsonResponse) => response,
    }),
    importProfile: builder.mutation({
      query: (body: ImportJsonBody) => getQueryArgs(`/profiles/import`, 'POST', body),
      transformErrorResponse,
      transformResponse: (response: ApiResponse<ImportJsonBody>) => response.body,
    }),
  }),
})

export const {
  endpoints,
  useGetProfilesQuery,
  useGetAllProfileOptionsQuery,
  useGetProfileOptionsQuery,
  useDeleteProfileMutation,
  usePutProfileMutation,
  usePutProfileOptionsMutation,
  usePostProfileMutation,
  useLazyExportProfileQuery,
  useImportProfileMutation,
} = profilesApi
