export default {
  warning: {
    backgroundColor: 'primary',
    '> div': {
      color: 'black',
    },
    m: 3,
  },
  success: {
    backgroundColor: 'successBackground',
    color: 'primary',
    justifyContent: 'center',
  },
  error: {
    backgroundColor: 'alertError',
    color: 'error',
    justifyContent: 'center',
  },
  successSmall: {
    backgroundColor: 'successBackground',
    borderRadius: 2,
    fontWeight: 0,
    width: 'fit-content',
    margin: `4.8rem auto`,
    '> div': {
      color: 'primary',
      justifyContent: 'center',
    },
    textAlign: 'center',
    padding: `1.2rem '2.4rem`,
  },
  primary: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    p: '1.2rem',
    background:
      'linear-gradient(0deg, rgba(1, 8, 24, 0.00) 0%, rgba(1, 8, 24, 0.50) 100%), #593EA5',
    boxShadow: '0px 5px 45px -5px rgba(0, 0, 0, 0.45)',
    color: 'aliceBlue',
    border: '1px solid',
    borderColor: 'blueYonder50',
    borderRadius: '1.2rem',
    zIndex: 'zIndex650',
  },
}
