import { CustomRuleData, useGetAllRulesQuery } from 'store/api/rules'
import { useLocation } from '@reach/router'
import { AnalyticsNavRelativePath } from 'components/Dashboard/NavBar/AnalyticsNavMenu'
import { useAppSelector } from 'store/hooks'

export function useGetExistingDraftRule(): {
  existingDraftRule: CustomRuleData | undefined
  areRulesLoading: boolean
  isAnalytics: boolean
} {
  const location = useLocation()
  const isAnalytics = [
    AnalyticsNavRelativePath.STATISTICS,
    AnalyticsNavRelativePath.ACTIVITY_LOG,
  ].some(path => location.pathname.includes(path))
  const draftCustomRule = useAppSelector(s => s.ruleTray.draftCustomRule)

  const { data: allRulesData, isLoading } = useGetAllRulesQuery(
    {
      profileId: draftCustomRule?.profileId?.toString() ?? '',
    },
    {
      skip: !draftCustomRule?.profileId?.toString() || !isAnalytics,
      refetchOnMountOrArgChange: true,
    },
  )

  return {
    existingDraftRule: allRulesData?.rules?.find(rule => rule.PK === draftCustomRule.PK),
    areRulesLoading: isLoading,
    isAnalytics,
  }
}
