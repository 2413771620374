import React, { ReactElement, useCallback, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateCustomRuleDraft } from 'store/customRules'
import RuleInput from './RuleInput'
import { Text, ThemeUIStyleObject } from 'theme-ui'
import customUnescape from 'utils/customUnescape'

const maxCommentLength = 5000

export default function Comment({ sx }: { sx?: ThemeUIStyleObject }): ReactElement {
  const dispatch = useAppDispatch()
  const draftCustomRule = useAppSelector(s => s.ruleTray.draftCustomRule)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onInputChange = useCallback(
    (event): void => {
      dispatch(
        updateCustomRuleDraft({
          comment: event.target.value,
        }),
      )
    },
    [dispatch],
  )

  return (
    <RuleInput
      name="comment-input"
      id="comment-input"
      data-testid="comment-input"
      onChange={onInputChange}
      value={customUnescape(draftCustomRule?.comment ?? '')}
      maxLength={maxCommentLength}
      rightContent={
        <Text variant="size12Weight400" sx={{ color: 'aliceBlue50', ...sx }}>
          {maxCommentLength - customUnescape(draftCustomRule?.comment ?? '').length} characters
          remaining
        </Text>
      }
      aria-label="optional comment"
      sx={{
        height: '3.8rem',
        resize: 'none',
      }}
      autoComplete="off"
      ref={inputRef}
      isTextarea
      label={
        <Text variant="size12Weight400" sx={{ color: 'aliceBlue70', ...sx }}>
          Comment
        </Text>
      }
    />
  )
}
