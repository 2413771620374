import React, { useRef, useState, ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { Dropdown, SimpleActionMenu, SimpleActionMenuItemProps, Svg, Button } from 'ui'
import LogoIcon from 'images/dashboard/navBar/logo-icon.svg'
import LogoArrowIcon from 'images/dashboard/navBar/logo-arrow.svg'
import GreenCheck from 'images/dashboard/navBar/green-check.svg'
import ControlDVersionAndDateInfo from 'components/Dashboard/Profiles/ControlDVersionAndDateInfo'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import useBoundaryElement from 'utils/useBoundaryElement'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { SetupGuideIntermediateStates, SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import useQueryString from 'utils/useQueryString'
import ArrowIcon from 'images/dashboard/arrow-right.svg'
import { MenuItem } from 'components/Dashboard/NavBar/NavBarHeader/MenuItem'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { setIsMobileNavMenuOpen, setIsNotificationsDialogOpen } from 'store/dashboard/dashboard'
import FocusLock from 'react-focus-lock'
import StatusIcon from 'images/dashboard/logoMenu/status-icon.svg'
import DocsIcon from 'images/dashboard/logoMenu/docs-icon.svg'
import FeatureIcon from 'images/dashboard/logoMenu/feature-icon.svg'
import ContactUsIcon from 'images/dashboard/logoMenu/contact-us-icon.svg'
import BarryIcon from 'images/dashboard/logoMenu/barry-icon.svg'
import DeviceIcons, { defaultIconName } from 'components/Dashboard/Devices/DeviceIcons'
import NotificationIcon from 'images/dashboard/navBar/mobile-notification-icon.svg'
import { useDetectDeviceQuery } from 'store/api/devices'
import { setIsBarryOpen } from 'store/barry/barry'
import useGetColorMode from 'utils/useGetColorMode'

export default function LogoMenu(): ReactElement {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const hideRef = useRef<() => void>()
  const breakpoint = useBreakpointIndex()
  const isMobile = breakpoint === 0
  const isTablet = breakpoint === 1
  const boundaryElement = useBoundaryElement(isMobile ? 'nav-tray-header' : 'nav-menu-left')
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const configuredDevice = useAppSelector(s => s.devices.configuredDevice)
  const { data: detectDeviceData } = useDetectDeviceQuery('')
  const { isLightMode } = useGetColorMode()

  const mainOptions: SimpleActionMenuItemProps[] = [
    {
      ariaLabel: 'status option',
      children: <MenuItem startIcon={StatusIcon} content="Status" to="/status" />,
    },
    {
      ariaLabel: 'docs option',
      children: (
        <MenuItem startIcon={DocsIcon} content="Docs" to={`${DOCS_CONTROL_D_DOMAIN}/docs`} />
      ),
    },
    {
      ariaLabel: 'request feature option',
      children: (
        <MenuItem
          startIcon={FeatureIcon}
          content="Request Feature"
          to={`${DOCS_CONTROL_D_DOMAIN}/discuss?tag=feature`}
        />
      ),
    },
    {
      ariaLabel: 'contact us option',
      children: <MenuItem startIcon={ContactUsIcon} content="Contact Us" to="/contact" />,
    },
  ]

  const notificationsOption: SimpleActionMenuItemProps = {
    ariaLabel: 'notifications option',
    isTopDivider: true,
    children: <MenuItem startIcon={NotificationIcon} content="Notifications" />,
    onClick: () => {
      dispatch(setIsNotificationsDialogOpen(true))
      hideRef.current?.()
    },
  }

  const barryAndVersionOptions: SimpleActionMenuItemProps[] = [
    {
      ariaLabel: 'talk to barry option',
      isTopDivider: true,
      children: (
        <MenuItem
          startIcon={BarryIcon}
          content={
            <Text variant="size15Weight500" sx={{ color: 'cyan800' }}>
              Talk to Barry
            </Text>
          }
          testId="barry-button"
        />
      ),
      onClick: () => {
        dispatch(setIsBarryOpen(true))
        dispatch(setIsMobileNavMenuOpen?.(false))
      },
    },
    {
      ariaLabel: 'Control D version',
      isTopDivider: true,
      children: (
        <MenuItem
          content={
            <Flex sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <ControlDVersionAndDateInfo />
            </Flex>
          }
          to={`${DOCS_CONTROL_D_DOMAIN}/changelog`}
          hasExternalLinkIcon={false}
          testId="controld-version"
        />
      ),
    },
  ]

  return (
    <Dropdown
      data-testid={`logo-menu-dropdown-button${
        !!configuredDevice || !!detectDeviceData ? '-configured' : ''
      }`}
      ariaLabel="logo menu"
      variant="transparent"
      sx={{
        p: 0,
        flexShrink: 0,
      }}
      tippyprops={{
        appendTo: !isNavBarExpanded || isTablet ? 'parent' : boundaryElement,
        animation: 'fade',
        onHide: () => {
          setIsOpen(false)
        },
        onShow: () => {
          setIsOpen(true)
        },
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        offset: [0, 8],
        theme: isLightMode ? 'light-org-dropdown' : 'org-dropdown',
        placement: 'bottom-start',
        maxWidth: '100%',
      }}
      dropdowncontent={
        isOpen ? (
          <FocusLock returnFocus={true}>
            <Flex
              sx={{
                width: ['100%', '24.6rem'],
                flexDirection: 'column',
              }}
            >
              {detectDeviceData && configuredDevice?.PK && <DeviceButton />}
              <SimpleActionMenu
                hideRef={hideRef}
                items={
                  !isNavBarExpanded || isTablet
                    ? [...mainOptions, notificationsOption, ...barryAndVersionOptions]
                    : [...mainOptions, ...barryAndVersionOptions]
                }
                sxContainer={{ pt: 0, px: '0.4rem', pb: '0.4rem' }}
                sxButton={{
                  maxHeight: 'auto',
                  borderRadius: '0.8rem',
                  px: '0.8rem',
                  py: '0.4rem',
                  m: 0,
                }}
                customHoverStyles={{
                  '& > div span': {
                    color: 'aliceBlue',
                  },
                  '& > div[data-testid=barry-button] span': {
                    color: 'cyan800',
                  },
                  '& > a span': {
                    color: 'aliceBlue',
                  },
                }}
              />
            </Flex>
          </FocusLock>
        ) : (
          <></>
        )
      }
    >
      <Flex sx={{ width: '7rem', flexShrink: 0, position: 'relative' }}>
        <Flex
          sx={{
            alignItems: 'center',
            gap: '1.2rem',
            'svg:last-of-type': {
              transform: `rotate(${isOpen ? '180deg' : 0})`,
            },
          }}
        >
          <Svg svg={LogoIcon} fill={isOpen ? 'aliceBlue' : 'aliceBlue60'} sx={{ flexShrink: 0 }} />
          <Svg
            svg={LogoArrowIcon}
            fill={isOpen ? 'aliceBlue' : 'aliceBlue60'}
            sx={{ flexShrink: 0 }}
          />
        </Flex>
        {(detectDeviceData || configuredDevice) && (
          <Flex
            sx={{
              position: 'absolute',
              right: '2rem',
              bottom: '0rem',
              width: 'fit-content',
              height: 'fit-content',
              p: '0.2rem',
              backgroundColor: isMobile ? 'grayLogoBG' : 'darkBodyBG',
              borderRadius: '50%',
            }}
          >
            <Svg svg={GreenCheck} />
          </Flex>
        )}
      </Flex>
    </Dropdown>
  )
}

function DeviceButton() {
  const dispatch = useAppDispatch()
  const { nav, qs, relocate } = useQueryString()
  const configuredDevice = useAppSelector(s => s.devices.configuredDevice)

  return (
    <Button
      variant="simple"
      ariaLabel="configured endpoint"
      data-testid="configured-endpoint"
      sx={{
        mt: '0.4rem',
        mx: '0.4rem',
        backgroundColor: 'blue800',
        py: '0.8rem',
        pl: '0.8rem',
        pr: '1.2rem',
        borderRadius: '0.8rem',
        justifyContent: 'space-between',
      }}
      onClick={() => {
        dispatch(setIsMobileNavMenuOpen?.(false))

        if (configuredDevice) {
          return nav({
            ...qs,
            helpPane: SetupGuideIntermediateStates.DNS,
            setupOs: configuredDevice.icon as SetupOs, // can be SetupOS or something new that has not been added to the SetupOs
            deviceId: configuredDevice.PK,
          })
        }
        relocate(`/dashboard/endpoints`)
      }}
    >
      <Flex sx={{ gap: '1.2rem', alignItems: 'center' }}>
        <Svg
          aria-label="endpoint icon"
          svg={DeviceIcons[configuredDevice?.icon ?? defaultIconName]}
          sx={{
            width: '2.4rem',
            height: '2.4rem',
            flexShrink: 0,
          }}
          fill="aliceBlue60"
        />
        <TextWithOverFlowAndTippyPopup
          variant="size14Weight600"
          sxText={{ color: 'aliceBlue60' }}
          sxTooltipContent={{ mx: '0.8rem' }}
          content={configuredDevice?.name}
          ariaLabel={configuredDevice?.name || ''}
        />
      </Flex>
      <Svg svg={ArrowIcon} fill="aliceBlue60" />
    </Button>
  )
}
