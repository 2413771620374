import React, { ReactNode, ElementType, ReactElement, useEffect } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import HeaderNavMenuButtonMobile from 'components/Dashboard/NavBar/HeaderNavMenuButton.mobile'
import { Button, Svg } from 'ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { useParams } from '@reach/router'
import { EditProfileUrlParams } from './Profiles'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setCurrentProfileId } from 'store/persist'
import InfoIcon from 'images/dashboard/info-icon.svg'
import useTutorialSlides from 'components/Dashboard/utils/useTutorialSlides'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import { TutorialType } from 'store/tutorial/tutorial'

export interface DashboardHeaderProps {
  children?: ReactNode
  icon?: ElementType
  rightContent?: ReactNode
  title: string
  description?: string | ReactNode
  sx?: ThemeUIStyleObject
  tutorialSlide?: number
  customTutorial?: TutorialType
}

export default function DashboardHeader({
  children,
  icon,
  title,
  description,
  rightContent,
  sx,
  tutorialSlide,
  customTutorial,
}: DashboardHeaderProps): ReactElement {
  const dispatch = useAppDispatch()
  const isMobile = useBreakpointIndex() === 0
  const { profileId } = useParams<EditProfileUrlParams>()
  const { data: userData } = useGetUser()
  const userPk = userData?.PK || ''
  const sessionTutorials = userData?.tutorials
  const shouldShowDescription = description && !!userPk && !sessionTutorials
  const isInAnalyticsMaintenanceMode = useAppSelector(s => s.errors.isInAnalyticsMaintenanceMode)

  useEffect(() => {
    if (!profileId) {
      dispatch(setCurrentProfileId(''))
    }
  }, [dispatch, profileId])

  return (
    <Flex
      data-testid="dashboard-header"
      sx={{
        backgroundColor: 'none',
        position: 'relative',
        width: '100%',
        borderBottom: ['1px solid', 'none'],
        borderBottomColor: 'white10',
        height: '5.5rem',
        justifyContent: 'space-between',
        pl: ['1.2rem', 0],
        pr: ['1.2rem', '1.6rem'],
        pt: ['0.8rem', '1.6rem'],
        mb: ['0.8rem', '2.4rem'],
        ...sx,
      }}
      className={isMobile ? 'reset-tippy-width centered-header-tippy mobile' : ''}
    >
      <Flex
        sx={{
          alignItems: 'center',
          gap: ['0.8rem', '1.2rem'],
        }}
      >
        <HeaderNavMenuButtonMobile />
        {icon && (
          <Flex
            sx={{
              width: ['2.4rem', '3.8rem'],
              height: ['2.4rem', '3.8rem'],
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderColor: ['transparent', 'blueYonder30'],
              borderRadius: '1.2rem',
              flexShrink: 0,
            }}
          >
            <Svg
              svg={icon}
              fill="aliceBlue"
              sx={{ height: '2.4rem', width: '2.4rem', flexShrink: 0, '& g': { opacity: 1 } }}
            />
          </Flex>
        )}
        <Flex
          sx={{
            mr: '1.2rem',
            alignItems: ['baseline', 'center', 'center'],
            flexDirection: ['column', 'row'],
            flexGrow: 1,
          }}
        >
          <Flex
            sx={{
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Flex
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                flexDirection: ['column', 'row'],
                mb: [0, '0.4rem', '0.4rem'],
              }}
            >
              <Flex sx={{ alignItems: 'center', gap: ['0.4rem', 0] }}>
                <TextWithOverFlowAndTippyPopup
                  testId="header-title"
                  content={title}
                  ariaLabel={title}
                  placement="bottom-start"
                  sxText={{
                    fontSize: ['1.5rem', '1.7rem'],
                    fontWeight: 600,
                    minWidth: '1px',
                    color: 'aliceBlue',
                  }}
                />
                {shouldShowDescription && isMobile && (
                  <AnimatedInfoButton
                    description={description}
                    tutorialSlide={tutorialSlide}
                    customTutorial={customTutorial}
                  />
                )}
              </Flex>
              {children && !isInAnalyticsMaintenanceMode && (
                <Flex
                  sx={{
                    ml: [0, '0.8rem'],
                    flexShrink: 0,
                  }}
                >
                  {children}
                </Flex>
              )}
            </Flex>
            {shouldShowDescription && !isMobile && (
              <AnimatedInfoButton
                description={description}
                tutorialSlide={tutorialSlide}
                customTutorial={customTutorial}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      {rightContent && !isInAnalyticsMaintenanceMode && (
        <Flex sx={{ mr: 0, alignItems: 'center', flexShrink: 0 }}>{rightContent}</Flex>
      )}
    </Flex>
  )
}

function AnimatedInfoButton({
  description,
  tutorialSlide = 0,
  customTutorial,
}: {
  description?: string | ReactNode
  tutorialSlide?: number
  customTutorial?: TutorialType
}): ReactElement {
  const { onInfoIconClick } = useTutorialSlides()
  const { wasTutorialViewed } = useTutorialSlides()

  return (
    <Button
      variant="transparent"
      data-testid="header-info-button"
      ariaLabel="view tutorial"
      sx={{
        width: 'fit-content',
        p: 0,
        color: wasTutorialViewed ? 'aliceBlue60' : 'banana',
        gap: '0.4rem',
        height: '1.2rem',
        justifyContent: 'flex-start',
        maxWidth: '100%',
        '&:hover': {
          color: wasTutorialViewed ? 'aliceBlue' : 'banana80',
          '& > .animation-block': {
            backgroundColor: wasTutorialViewed ? 'transparent' : 'banana80',
            'svg path': {
              fill: wasTutorialViewed ? 'aliceBlue' : 'black',
            },
          },
        },
      }}
      onClick={() => onInfoIconClick?.(tutorialSlide, customTutorial)}
    >
      {typeof description === 'string' ? (
        <TextWithOverFlowAndTippyPopup
          variant="size12Weight400"
          testId="header-description"
          content={description}
          ariaLabel={description || ''}
          sxText={{
            display: ['none', 'inline-block'],
          }}
          maxWidth="450px"
        />
      ) : (
        <Flex data-testid="header-description" sx={{ display: ['none', 'flex'] }}>
          {description}
        </Flex>
      )}
      <Flex
        className="animation-block"
        sx={{
          flexShrink: 0,
          p: wasTutorialViewed ? 0 : '0.2rem',
          borderRadius: '100%',
          backgroundColor: wasTutorialViewed ? 'transparent' : 'banana',
          '@-webkit-keyframes rotation': {
            '0%': { boxShadow: '0 0 0 0 rgba(214, 166, 45, 1)' },
            '100%': { boxShadow: '0 0 10px 8px transparent', borderWidth: '2px' },
          },
          WebkitAnimation: 'rotation 2s infinite linear',
          animation: wasTutorialViewed ? 'none' : 'rotation 2s infinite linear',
        }}
      >
        <Svg
          svg={InfoIcon}
          fill={wasTutorialViewed ? 'aliceBlue60' : 'black'}
          sx={{ width: '1.2rem', height: '1.2rem', flexShrink: 0 }}
        />
      </Flex>
    </Button>
  )
}
