import noop from 'lodash/noop'
import React, { ReactElement, forwardRef } from 'react'
import { Button, ButtonProps } from 'ui/Buttons'

export default forwardRef<HTMLButtonElement, ButtonProps>(function CircleIconWrapperButton(
  { children, sx, onClick = noop, hasBackgroundOnHover = false, ...rest },
  ref,
): ReactElement {
  return (
    <Button
      ref={ref}
      onClick={onClick}
      variant="simple"
      sx={{
        width: ['fit-content', '4.8rem'],
        height: ['fit-content', '4.8rem'],
        borderRadius: '100%',
        backgroundColor: ['transparent', 'white4'],
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        flexShrink: 0,
        p: [0, '0.8rem'],
        '&:hover': {
          ':not([disabled])': {
            backgroundColor: [hasBackgroundOnHover ? 'white10' : 'transparent', 'white10'],
            color: 'white',
          },
        },
        ':disabled': {
          '& svg path': {
            fill: 'white10',
          },
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  )
})
