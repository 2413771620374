import React, { ReactElement } from 'react'
import { useAppSelector } from 'store/hooks'
import QueryFilterDropdownContent from './QueryFilterDropdownContent'
import QueryFilterDropdown from './QueryFilterDropdown'
import { QueriesFilterMenuType } from '../FiltersContainer'

export enum RCODE {
  NOERROR = 'NOERROR',
  SERVFAIL = 'SERVFAIL',
  NXDOMAIN = 'NXDOMAIN',
  REFUSED = 'REFUSED',
}
const rCodes = [RCODE.NOERROR, RCODE.SERVFAIL, RCODE.NXDOMAIN, RCODE.REFUSED]

export default function CodesDropdown(): ReactElement {
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)

  const selectedCode = rCodes[filterParameters[QueriesFilterMenuType.RCODE] || ''] || 'RCODEs'

  return (
    <QueryFilterDropdown selectedValue={selectedCode} type={QueriesFilterMenuType.RCODE}>
      <QueryFilterDropdownContent
        type={QueriesFilterMenuType.RCODE}
        codes={rCodes || []}
        isSearchVisible={false}
      />
    </QueryFilterDropdown>
  )
}
