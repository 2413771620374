import React, { ReactElement, ReactNode, useEffect, useRef } from 'react'
import { Flex } from 'theme-ui'
import { warningMessageRef } from 'components/WarningMessage'
import { scrollingContainerRef } from './Dashboard'
import { useAppSelector } from 'store/hooks'
import MaintenanceModePane from './MaintenanceModePane/MaintenanceModePane'

export default function DashboardContainer({
  children,
  header,
  subHeader,
  loadingPlaceholder,
  isSubHeaderFixed = true,
}: {
  children?: ReactNode
  header: ReactNode
  subHeader?: ReactNode
  loadingPlaceholder?: ReactNode | ReactElement
  isSubHeaderFixed?: boolean
}): ReactElement {
  const headerRef = useRef<HTMLDivElement>(null)
  const subHeaderRef = useRef<HTMLDivElement>(null)
  const isInAnalyticsMaintenanceMode = useAppSelector(s => s.errors.isInAnalyticsMaintenanceMode)

  useEffect(() => {
    scrollingContainerRef.current && (scrollingContainerRef.current.scrollTop = 0)
  }, [])

  return (
    <Flex sx={{ flexDirection: 'column', minHeight: '100vh' }}>
      <Flex
        ref={headerRef}
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: 'zIndex240',
        }}
      >
        <Flex
          sx={{
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'blue800',
            background: (theme): string => {
              return `linear-gradient(180deg, ${theme.colors?.blue800} 86%, rgba(18, 19, 28, 0) 100%)`
            },
          }}
        >
          {header}
          {subHeader && !isInAnalyticsMaintenanceMode && isSubHeaderFixed && (
            <Flex
              sx={{
                position: 'relative',
                width: '100%',
                pl: ['1.2rem', 0],
                pr: ['1.2rem', '1.6rem'],
                pb: '1.2rem',
              }}
            >
              {subHeader}
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex
        sx={{
          position: 'relative',
          flexDirection: 'column',
          mb: `${warningMessageRef.current?.clientHeight || 0}px`,
          minHeight: `calc(100vh - ${headerRef.current?.clientHeight}px - 32px)`,
        }}
      >
        {subHeader && !isSubHeaderFixed && !isInAnalyticsMaintenanceMode && (
          <Flex
            ref={subHeaderRef}
            sx={{
              width: '100%',
              pl: ['1.2rem', 0],
              pr: ['1.2rem', '1.6rem'],
              pb: ['0.8rem', '2.4rem'],
            }}
          >
            {subHeader}
          </Flex>
        )}
        <Flex
          sx={{
            // should be absolute for proper rendering of the virtualized grid/list
            position: 'absolute',
            top: isSubHeaderFixed ? 0 : subHeaderRef.current?.clientHeight,
            width: '100%',
            pl: 0,
            pr: [0, '1.6rem', '1.6rem'],
            flexDirection: 'column',
          }}
        >
          {loadingPlaceholder}
        </Flex>
        {isInAnalyticsMaintenanceMode ? (
          <Flex sx={{ width: '100%', mt: '9rem', justifyContent: 'center', alignItems: 'center' }}>
            <MaintenanceModePane isAnalytics />
          </Flex>
        ) : (
          <Flex
            sx={{
              pl: 0,
              pr: [0, '1.6rem', '1.6rem'],
              opacity: loadingPlaceholder ? 0 : 1,
              flexDirection: 'column',
              mb: `${warningMessageRef.current?.clientHeight || 0}px`,
            }}
          >
            {children}
          </Flex>
        )}
        <Flex
          sx={{
            width: 'fit-content',
            position: 'fixed',
            bottom: 0,
            zIndex: 'zIndex240',
          }}
        ></Flex>
      </Flex>
    </Flex>
  )
}
