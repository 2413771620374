import React, { ReactElement, ReactNode } from 'react'
import { Flex, Text, ThemeUICSSObject } from 'theme-ui'

export function Table({ children }: { children: ReactNode }): ReactElement {
  return (
    <Flex
      as="table"
      sx={{
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: 'cardBg',
        borderRadius: '1.2rem',
        border: '1px solid',
        borderColor: 'blueYonder15',
        p: '1.2rem',
        color: 'aliceBlue60',
        gap: '0.8rem',
      }}
    >
      {children}
    </Flex>
  )
}

export function TableBody({ children }: { children: ReactNode }): ReactElement {
  return (
    <Flex as="tbody" sx={{ flexDirection: 'column', gap: '0.8rem' }}>
      {children}
    </Flex>
  )
}

export function TableHeader({
  names,
  sx,
}: {
  names: Array<string | null>
  sx?: ThemeUICSSObject
}): ReactElement {
  return (
    <thead>
      <Flex
        as="tr"
        sx={{
          justifyContent: 'space-between',
          color: 'aliceBlue30',
          px: '1.2rem',
        }}
      >
        {names.map((name, index) => (
          <Flex as="th" key={`${name}-${index}`} sx={{ flex: '1 0 0', ...sx }}>
            <Text variant="size12Weight700Uppercase">{name}</Text>
          </Flex>
        ))}
      </Flex>
    </thead>
  )
}

export function TableRow({
  dataTestId,
  children,
}: {
  dataTestId: string
  children: ReactNode
}): ReactElement {
  return (
    <Flex
      as="tr"
      data-testid={dataTestId}
      sx={{
        height: '3.8rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: '1.2rem',
        borderRadius: '0.8rem',
        fontSize: '1.5rem',
        '&:nth-of-type(odd)': {
          backgroundColor: 'cardBg',
        },
      }}
    >
      {children}
    </Flex>
  )
}

export function TableCell({
  sx,
  children,
  testId,
}: {
  sx?: ThemeUICSSObject
  children: ReactNode
  testId?: string
}): ReactElement {
  return (
    <Flex as="td" sx={{ flex: '1 0 0', ...sx }} tabIndex={0} data-testid={testId}>
      {children}
    </Flex>
  )
}
