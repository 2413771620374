import React, { ReactNode, ReactElement, useEffect, useLayoutEffect } from 'react'
import { LocationContext, useLocation } from '@reach/router'
import StaticPageWrapper from 'components/StaticPageWrapper'
import { PageQuery } from 'components/SetupGuide/SetupGuide.interface'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import { Flex, useColorMode } from 'theme-ui'
import { WarningMessage } from 'components/WarningMessage'
import useShouldRenderOnClient from 'utils/useShouldRenderOnClient'
import useGetUserState from 'store/api/user/useGetUserState'
import useHideBodyLoader from 'utils/useHideBodyLoader'
import BarryDialog from 'components/Barry/BarryComponents/BarryDialog'
import { useAppSelector } from 'store/hooks'
import { ColorMode } from 'store/dashboard/dashboard'

function onNodeKeyDown(e: KeyboardEvent) {
  // extending links to work with space button on key press
  // need for keyboard accessibility
  const spaceCode = 'Space'
  const enterCode = 'Enter'

  if (e && e.target) {
    const target = e.target as HTMLElement

    const isCheckboxPressesWithEnter =
      target.localName === 'input' &&
      (target as HTMLInputElement).type === 'checkbox' &&
      e.code == enterCode
    const isLinkPressesWithSpace = target.localName === 'a' && e.code == spaceCode
    const isSelectPressesWithEnter = target.localName === 'select' && e.code == enterCode

    if (isLinkPressesWithSpace) {
      // getting rid of automatic scroll on space click
      e.preventDefault()
    }

    if (isCheckboxPressesWithEnter || isLinkPressesWithSpace || isSelectPressesWithEnter) {
      target.click()
    }
  }
}

export const noRedirectParam = '?noRedirect=true'
export const noRedirectHomePagePath = `/${noRedirectParam}`

const excludedPaths = ['/provision', '/invoice']

/**
 * Layout component that automatically wraps all pages, managed by `gatsby-plugin-layout`.
 * Currently used to enable animated transitions on route changes in the home page.
 */
export default function Layout({
  children,
  pageContext,
}: {
  data: PageQuery
  children?: ReactNode
  pageContext: { layout?: string }
} & LocationContext): ReactElement {
  const { sessionAutologin } = useGetUserState()
  const shouldRenderOnClient = useShouldRenderOnClient()
  const location = useLocation()
  const [colorMode, setColorMode] = useColorMode()
  const dashboardTheme = useAppSelector(s => s.dashboard.theme)

  useHideBodyLoader(!excludedPaths.includes(location.pathname))

  useLayoutEffect(() => {
    if (pageContext.layout === 'dashboard') {
      setColorMode(dashboardTheme)
    } else {
      setColorMode(ColorMode.DARK)
    }
  }, [colorMode, dashboardTheme, pageContext.layout, setColorMode])

  useEffect(() => {
    if (pageContext.layout === 'dashboard') {
      sessionAutologin()
    }
  }, [pageContext.layout, sessionAutologin])

  useEffect(() => {
    document.addEventListener('keydown', onNodeKeyDown)
    return () => document.removeEventListener('keydown', onNodeKeyDown)
  }, [])

  // should scroll up when changing page
  useEffect(() => {
    if (shouldRenderOnClient) {
      window['___gatsby'].scrollTop = 0
    }
  }, [shouldRenderOnClient, location.pathname])

  if (pageContext.layout === 'dashboard') {
    return (
      <Flex
        sx={{
          position: 'sticky',
          bottom: '0',
          width: '100vw',
          flexDirection: 'column',
          zIndex: 'zIndex0',
          flex: 1,
        }}
      >
        <WarningMessage />
        {shouldRenderOnClient && <DashboardLayout />}
      </Flex>
    )
  } else {
    // all other pages are rendered without a layout component

    return (
      <StaticPageWrapper>
        {children}
        {shouldRenderOnClient && <BarryDialog />}
      </StaticPageWrapper>
    )
  }
}
