import React, { ElementType, ReactElement, ReactNode } from 'react'
import { Box, Flex, Text, Spinner, ThemeUIStyleObject } from 'theme-ui'
import { Button, Svg } from 'ui'
import { vibrate } from 'utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import {
  TrackingEventAction,
  TrackingEventCategory,
  useTrackingHelper,
} from 'utils/useTrackingHelper'
import { useLocation } from '@reach/router'
import { setIsMobileNavMenuOpen } from 'store/dashboard/dashboard'
import useQueryString from 'utils/useQueryString'
import isNumber from 'lodash/isNumber'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export interface NavMenuItemProps {
  label: string
  activeIcon: ElementType
  inactiveIcon?: ElementType
  relativePath?: string
  onClick?: () => void
  as?: string | ReactNode
  isNavBarExpanded?: boolean
  sx?: ThemeUIStyleObject
  count?: number
  isLoading?: boolean
  specificItemAction?: () => void
  isSubItem?: boolean
}

const NavMenuItem = (props: NavMenuItemProps): ReactElement => {
  const {
    label,
    activeIcon,
    inactiveIcon,
    relativePath,
    sx,
    count,
    onClick = (): void => {},
    isLoading,
    specificItemAction,
    isSubItem,
  } = props

  const isMobile = useBreakpointIndex() === 0
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { trackEvent } = useTrackingHelper()
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  /**
   * using relocate to preserve the query string when navigating to a new page
   * this is needed because the orgId is stored in the query string
   */
  const { relocate } = useQueryString()
  const isActive =
    relativePath &&
    [relativePath, `${relativePath}/`].some(path => location.pathname.endsWith(path))
  const isCountVisible = isNumber(count)
  const { data: userData } = useGetUser()

  return (
    <Box sx={{ ...sx, px: 0 }}>
      <Button
        variant="transparent"
        data-testid={`side-nav-link-${relativePath}`}
        ariaLabel={`side navigation link to ${relativePath}`}
        tabIndex={!isMobile ? 0 : -1}
        onClick={(event): void => {
          specificItemAction?.()

          if (userData?.haptics) {
            vibrate(20)
          }

          if (relativePath) {
            trackEvent({
              category: TrackingEventCategory.Nav,
              action: relativePath.split('-')[0] as TrackingEventAction,
            })
            if (event.ctrlKey || event.metaKey) {
              const href = location.href.slice(0, location.href.lastIndexOf('/'))
              window.open(
                `${href}/${relativePath}${location.search}`,
                '_blank',
                'noopener, noreferrer',
              )
            } else {
              relocate(`/dashboard/${relativePath}`)
            }
            dispatch(setIsMobileNavMenuOpen?.(false))
          } else {
            event.preventDefault()
            onClick()
          }
        }}
        sx={{
          width: [
            '100%',
            isCountVisible || isLoading ? '100%' : 'inherit',
            isNavBarExpanded || isCountVisible ? '100%' : 'inherit',
          ],
          height: '3.8rem',
          textDecoration: 'none',
          gap: isCountVisible ? '0.8rem' : 'none',
          cursor: 'pointer',
          fontWeight: '700',
          alignItems: 'center',
          m: isCountVisible ? 'none' : 'auto',
          justifyContent: [
            'space-between',
            isCountVisible ? 'space-between' : 'center',
            'space-between',
          ],
          color: isActive ? 'aliceBlue' : 'aliceBlue70',
          pl: ['0.8rem'],
          pr: ['0.8rem', '0.8rem', isNavBarExpanded ? '1.2rem' : '0.8rem'],
          pt: '0.8rem',
          pb: ['0.8rem'],
          borderRadius: '1.2rem',
          boxShadow: isActive ? 'inset 0 0 0 1px rgb(118, 150, 224)' : 'none',
          backgroundColor: isActive ? (isMobile ? 'littleBoyBlue5' : 'activeNavMenuItem') : 'none',
          ...(!isActive && {
            ':hover path': {
              fill: 'white',
              opacity: 1,
            },
            ':hover': {
              background: 'white6',
              color: 'white',
              '.nav-item-label': {
                color: 'white',
                opacity: 1,
              },
            },
          }),
        }}
      >
        <Flex
          sx={{
            justifyContent: ['left', 'right', isNavBarExpanded ? 'left' : 'center'],
            alignItems: 'center',
          }}
        >
          {activeIcon && (
            <Svg
              svg={isActive ? activeIcon : inactiveIcon || activeIcon}
              data-testid="item"
              fill={isActive ? 'aliceBlue' : 'aliceBlue60'}
              sx={{
                width: '2.4rem',
                height: '2.4rem',
                flexShrink: 0,
              }}
            />
          )}
          <Text
            className="nav-item-label"
            variant="size15Weight700"
            sx={{
              display: ['inherit', 'none', isNavBarExpanded ? 'inherit' : 'none'],
              whiteSpace: 'nowrap',
              color: isActive ? 'aliceBlue' : 'aliceBlue60',
              fontWeight: isSubItem ? 500 : 700,
              ml: ['1.2rem', 0, isNavBarExpanded ? '1.2rem' : 0],
            }}
          >
            {label}
          </Text>
        </Flex>

        <Flex
          sx={{
            alignItems: 'center',
            height: isLoading || isCountVisible ? '2.8rem' : 0,
            overflow: 'hidden',
            ml: 'auto',
          }}
        >
          {isLoading ? (
            <Spinner
              data-testid="side-nav-spinner"
              color="aliceBlue"
              sx={{
                width: '2rem',
                height: '2rem',
              }}
            />
          ) : (
            <Text variant="size15Weight700">{count}</Text>
          )}
        </Flex>
      </Button>
    </Box>
  )
}

export default NavMenuItem
