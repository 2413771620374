import React, { MutableRefObject, ReactElement } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import useDropdownKeyboardControl from 'utils/useDropdownKeyboardControl'
import { useAppSelector } from 'store/hooks'
import { SimpleActionMenuItem, SimpleActionMenuItemProps } from './SimpleActionMenuItem'

export interface SimpleActionMenuProps {
  dataTestId?: string
  items: SimpleActionMenuItemProps[]
  sxContainer?: ThemeUIStyleObject
  sxButton?: ThemeUIStyleObject
  customHoverIndex?: number
  customHoverStyles?: ThemeUIStyleObject
  hasTopBorder?: boolean
  highlightedBackgroundColor?: string
  hideRef?: MutableRefObject<(() => void) | undefined>
  isOpen?: boolean
  shouldHighlightOnHover?: boolean
  tabIndex?: number
  className?: string
}
export default function SimpleActionMenu({
  dataTestId,
  items,
  sxContainer,
  sxButton,
  customHoverIndex,
  customHoverStyles,
  hasTopBorder,
  hideRef,
  isOpen,
  highlightedBackgroundColor = 'white6',
  shouldHighlightOnHover = true,
  tabIndex,
  className,
}: SimpleActionMenuProps): ReactElement {
  const isSearchDropdownVisible = useAppSelector(s => s.dashboard.isSearchDropdownVisible)
  const hoverIndex = useDropdownKeyboardControl(
    items.length,
    isSearchDropdownVisible,
    hideRef,
    isOpen,
  )
  const keyboardHoverIndex = isSearchDropdownVisible ? customHoverIndex : hoverIndex

  return (
    <Flex
      data-testid={dataTestId}
      sx={{
        flexDirection: 'column',
        px: 0,
        pt: 0,
        py: hasTopBorder ? '0.4rem' : '0.4rem',
        gap: '0.1rem',
        borderTop: hasTopBorder ? '1px solid' : 'none',
        borderColor: hasTopBorder ? 'white15' : 'none',
        lineHeight: hasTopBorder ? '2.2rem' : 'none',
        ...sxContainer,
      }}
      className={className || 'show-scrollbar'}
    >
      {items.map(({ sx, ...item }, index) => (
        <SimpleActionMenuItem
          key={index}
          sx={{ ...sxButton, ...sx }}
          {...item}
          isKeyboardHover={keyboardHoverIndex === index}
          customHoverStyles={customHoverStyles}
          isBackgroundHighlighted
          highlightedBackgroundColor={highlightedBackgroundColor}
          shouldHighlightOnHover={shouldHighlightOnHover}
          tabIndex={tabIndex}
        />
      ))}
    </Flex>
  )
}
