import React, { ReactElement } from 'react'
import { Image, ThemeUIStyleObject } from 'theme-ui'
import { BASE_URL_ASSETS } from 'gatsby-env-variables'
import useGetColorMode from 'utils/useGetColorMode'

export enum IconType {
  FILTERS = 'filters',
  SERVICES = 'services',
  LOCATIONS = 'locations',
}

export const globeIcon = 'globe'

export interface ExternalIcon {
  icon: string
  type: IconType
}

// we can change external svg color only using css filter
export const colorFilters = {
  grayscale: 'grayscale(1) invert(1)',
  white: 'brightness(0) invert(1)',
  white50: 'brightness(0) invert(1) opacity(50%)',
  successOpaque:
    'brightness(0) saturate(100%) invert(72%) sepia(21%) saturate(2267%) hue-rotate(109deg) brightness(105%) contrast(79%)',
  lightSteelBlue:
    'brightness(0) saturate(100%) invert(84%) sepia(2%) saturate(5536%) hue-rotate(191deg) brightness(90%) contrast(88%)',
  greenApple:
    'brightness(0) saturate(100%) invert(83%) sepia(55%) saturate(5167%) hue-rotate(49deg) brightness(100%) contrast(106%)',
  aliceBlue:
    'brightness(0) saturate(100%) invert(89%) sepia(79%) saturate(4674%) hue-rotate(179deg) brightness(104%) contrast(110%)',
  aliceBlue60:
    'brightness(0) saturate(100%) invert(89%) sepia(79%) saturate(4674%) hue-rotate(179deg) brightness(104%) contrast(110%) opacity(60%)',
}

export default function ExternalSvgIcon({
  className,
  icon,
  type,
  sx,
  fill,
  testId,
  defaultColor,
}: {
  className?: string
  icon: string
  type: IconType
  sx?: ThemeUIStyleObject
  fill?: string
  testId?: string
  defaultColor?: string
}): ReactElement | null {
  const { isLightMode } = useGetColorMode()
  if (!icon) {
    return null
  }

  return (
    <Image
      data-testid={testId}
      className={className}
      src={
        type === IconType.LOCATIONS
          ? `${BASE_URL_ASSETS}/icons/flags/round/svg/${icon}.svg`
          : `${BASE_URL_ASSETS}/icons/${type}/svg/${
              icon.startsWith('x-') ? 'third-party' : icon
            }.svg`
      }
      sx={{
        ...(fill && !isLightMode ? { filter: colorFilters[fill] } : {}),
        ...(icon === '?' && !isLightMode ? { filter: colorFilters.white } : {}),
        ...sx,
      }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src =
          type === IconType.LOCATIONS
            ? `${BASE_URL_ASSETS}/icons/flags/round/svg/globe.svg`
            : `${BASE_URL_ASSETS}/icons/general/svg/placeholder.svg`

        if (defaultColor) {
          currentTarget.style.filter = colorFilters[defaultColor]
        }
      }}
    />
  )
}
