import { IGatsbyImageData } from 'gatsby-plugin-image'
import { ReactElement, ReactNode, ElementType } from 'react'

export enum Option {
  DNS = 'DNS Resolvers',
  DOH = 'DoH',
}

export enum ResolverType {
  LEGACY = 'legacy',
  DOH = 'doh',
  DOT = 'dot',
  DOQ = 'doq',
  DOH3 = 'doh3',
  SECURE = 'secure',
}

// from TypeMap in https://gitlab.int.windscribe.com/controld/backend/api/-/blob/210-eliminate-default-profile-device/includes/models/DevicesModel.php
export enum SetupOs {
  IOS = 'mobile-ios',
  ANDROID = 'mobile-android',
  WINDOWS = 'desktop-windows',
  MAC = 'desktop-mac',
  LINUX = 'desktop-linux',
  CHROME = 'browser-chrome',
  FIREFOX = 'browser-firefox',
  EDGE = 'browser-edge',
  BRAVE = 'browser-brave',
  BROWSER_OTHER = 'browser-other',
  APPLE_TV = 'tv-apple',
  ANDROID_TV = 'tv-android',
  FIRE_TV = 'tv-firetv',
  SAMSUNG_TV = 'tv-samsung',
  TV_OTHER = 'tv',
  OPEN_WRT = 'router-openwrt',
  UBIQUITI = 'router-ubiquiti',
  ASUS = 'router-asus',
  DD_WRT = 'router-ddwrt',
  FRESH_TOMATO = 'router-freshtomato',
  GL_INET = 'router-glinet',
  SYNOLOGY = 'router-synology',
  PF_SENSE = 'router-pfsense',
  OPN_SENSE = 'router-opnsense',
  FIREWALLA = 'router-firewalla',
  GENERIC_LINUX = 'router-linux',
  WINDOWS_SERVER = 'router-windows',
  ROUTER_OTHER = 'router',

  MAC_OS_BIG_SUR = 'mac_os_big_sur',
  MAC_OS_X = 'mac_os_x',
  MAC_OS = 'mac_os',
  APPLE_TV_OS_13 = 'apple_tv_os_13-',
  APPLE_TV_OS_14 = 'apple_tv_os_14+',
  WINDOWS_11 = 'windows11',
  WINDOWS_10_OR_OLDER = 'windows10',
  WINDOWS_OS = 'windows',
  LINUX_OS = 'linux',
  ROUTER = 'router',
  ANDROID_OS = 'android_os',
  IOS_OS = 'ios_os',
  OTHER_DEVICE = 'Other Endpoint', // used in Add Edit Endpoint
}

export enum SetupGuideIntermediateStates {
  DNS = 'dns', // the first configure device screen
  Platform = 'platform',
  MacVersion = 'macVersion',
  WindowsVersion = 'windowsVersion',
  Configure = 'configure',
  AppleTVVersion = 'appleTVVersion',
}

export enum SetupGuideMode {
  AUTO = 'Automatic',
  MANUAL = 'Manual',
}

export const SetupGuidePaneTypes = {
  ...SetupOs,
  ...SetupGuideIntermediateStates,
}
export interface PageQuery {
  [key: string]: {
    childImageSharp: {
      fluid: IGatsbyImageData
    }
  }
}

export interface StepInfo {
  text?: string | ReactElement
  image?: IGatsbyImageData
  imageWidth?: string[]
  titleIcon?: ElementType
  content?: ReactNode
  shouldRemoveTopPadding?: boolean
  shouldPresentTroubleshootingAlert?: boolean | undefined
  shouldNotShowFooter?: boolean
}
