import React, { ReactElement } from 'react'
import { setIsBarryOpen } from 'store/barry/barry'
import { useAppDispatch } from 'store/hooks'
import ExternalLink from 'ui/ExternalLink/ExternalLink.base'
import { SmoothAnchorLink } from 'ui/SmoothAnchorLink'

export function InternalOrExternalLink({
  to,
  href = '',
  children,
  dismiss,
  style,
  tabIndex,
}: {
  to?: string
  href?: string
  style?: React.CSSProperties
  tabIndex?: number
  children: ReactElement
  dismiss?: () => void
}): ReactElement {
  const dispatch = useAppDispatch()

  return to ? (
    <ExternalLink
      to={to}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        if (to === 'barry') {
          event?.preventDefault()
          dispatch(setIsBarryOpen(true))
        } else if (dismiss) {
          dismiss()
        }
      }}
      {...{ style, tabIndex }}
    >
      {children}
    </ExternalLink>
  ) : (
    <SmoothAnchorLink
      ariaLabel={`scroll to ${href}`}
      testId={href}
      to={href}
      onClick={dismiss}
      sx={{ width: '100%', textDecoration: 'none', ...style }}
      tabIndex={tabIndex}
    >
      {children}
    </SmoothAnchorLink>
  )
}
