import React, { ReactElement } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { QueriesFilterMenuType } from '../FiltersContainer'
import { devicesApi, useGetDevicesQuery } from 'store/api/devices'
import { useDeviceClientsData } from 'components/Dashboard/Analytics/DeviceClients/useDeviceClientsData'
import DeviceDropdown from 'components/Dashboard/DeviceDropdown/DeviceDropdown'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { Flex } from 'theme-ui'

export default function EndpointsDropdown(): ReactElement {
  const dispatch = useAppDispatch()
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)
  const { data: devicesData } = useGetDevicesQuery('')
  const { selectedDevice, setSelectedDeviceId } = useDeviceClientsData()
  const isMobile = useBreakpointIndex() === 0

  const selectedEndpoint =
    selectedDevice ||
    devicesData?.devices?.find(
      device => filterParameters[QueriesFilterMenuType.ENDPOINT] === device.PK,
    )

  return (
    <Flex
      sx={{
        position: 'relative',
        '.show-scrollbar': {
          scrollbarWidth: 'none',
        },
      }}
      className={isMobile ? 'reset-tippy-width-subheader mobile' : ''}
    >
      <DeviceDropdown
        dropdownDevices={devicesData?.devices || []}
        deviceId={selectedEndpoint?.device_id}
        boundaryElementTestId="parent"
        sxButtonContent={{
          pl: [0, '0.8rem'],
          py: '0.8rem',
          pr: '0.8rem',
          mr: 0,
          '& > span': {
            fontSize: '1.4rem',
            fontWeight: 700,
          },
        }}
        onClick={async deviceId => {
          setSelectedDeviceId(deviceId)
          if (deviceId) {
            await dispatch(devicesApi.endpoints.getDeviceClients.initiate(deviceId))
          }
        }}
        maxWidth={isMobile ? '99%' : '100%'}
      />
    </Flex>
  )
}
