import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import FiltersDropdown from './Dropdowns/FiltersDropdown'
import ServicesDropdown from './Dropdowns/ServicesDropdown'
import LocationsDropdown from './Dropdowns/LocationsDropdown'
import ActionsDropdown from './Dropdowns/ActionsDropdown'
import { Button, Svg } from 'ui'
import { resetFilterParameters } from 'store/activityLog'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import EndpointsDropdown from './Dropdowns/EndpointsDropdown'
import ClearIcon from 'images/dashboard/clear-icon.svg'
import CodesDropdown from './Dropdowns/CodesDropdown'
import TypesDropdown from './Dropdowns/TypesDropdown'
import ClientsDropdown from './Dropdowns/ClientsDropdown'
import { useDeviceClientsData } from '../DeviceClients/useDeviceClientsData'
import omit from 'lodash/omit'
import Tippy from '@tippyjs/react'
import useGetColorMode from 'utils/useGetColorMode'

export enum QueriesFilterMenuType {
  ENDPOINT = 'deviceId',
  CLIENT = 'clientId',
  ACTION = 'action',
  RCODE = 'statusCode',
  RTYPE = 'rrType',
  PROTOCOL = 'protocol',
  FILTERS = 'filters',
  SERVICES = 'services',
  LOCATIONS = 'locations',
}

export default function FiltersContainer(): ReactElement {
  const dispatch = useAppDispatch()
  const { setSelectedDeviceId } = useDeviceClientsData()
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)
  const isClearFiltersIconVisible = Object.values(omit(filterParameters, 'endTs', 'startTs')).some(
    param => param && param !== 'all',
  )
  const { isLightMode } = useGetColorMode()

  return (
    <Flex data-testid="filters-container" sx={{ flexWrap: 'wrap' }}>
      <EndpointsDropdown />
      <ClientsDropdown />
      <ActionsDropdown />
      <CodesDropdown />
      <TypesDropdown />
      <FiltersDropdown />
      <ServicesDropdown />
      <LocationsDropdown />

      {isClearFiltersIconVisible && (
        <Tippy
          content={'Clear filters'}
          arrow={false}
          offset={[0, 4]}
          theme={isLightMode ? 'light-org-tooltip' : 'org-tooltip'}
        >
          <Button
            data-testid="clear-filters"
            ariaLabel="clear filters button"
            variant="simple"
            sx={{ p: 0, flexShrink: 0 }}
            onClick={() => {
              setSelectedDeviceId('')
              dispatch(resetFilterParameters())
            }}
          >
            <Svg
              svg={ClearIcon}
              fill="aliceBlue60"
              sx={{
                width: '2.4rem',
                height: '2.4rem',
              }}
            />
          </Button>
        </Tippy>
      )}
    </Flex>
  )
}
