import React, { Dispatch, ReactElement } from 'react'
import { Input, MultiSelectInput, Option } from 'ui'
import { ActionType, DeviceActionType } from '../SettingsState'
import { Text } from 'theme-ui'
import { deviceNameMask } from './helpers'
import { Flex } from '@theme-ui/components'
import { Setter } from 'utils'

export default function AddOrEditDeviceInput({
  devicePk,
  settingsStateName,
  settingsDispatch,
  namesOrEmails,
  setNamesOrEmails,
}: {
  devicePk?: string
  settingsStateName?: string
  settingsDispatch: Dispatch<ActionType>
  namesOrEmails?: Option[]
  setNamesOrEmails: Setter<Option[] | undefined>
}): ReactElement {
  return devicePk ? (
    <Input
      name="device-name-input"
      data-testid="device-name-input"
      aria-label="device name input"
      label="Names or Emails"
      value={settingsStateName}
      sx={{
        maxHeight: '3.8rem',
      }}
      onChange={(event): void => {
        settingsDispatch({
          type: DeviceActionType.DEVICE_NAME,
          payload: event.target.value,
        })
      }}
    />
  ) : (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Text
        variant="size12Weight400"
        sx={{
          color: 'aliceBlue70',
          mb: '0.8rem',
        }}
      >
        Names or Emails
      </Text>
      <MultiSelectInput
        testId="device-name-input"
        isSmallInput
        ariaLabel="device name input"
        isDevice
        value={namesOrEmails}
        setValue={setNamesOrEmails}
        onChange={(event?: React.ChangeEvent<HTMLSelectElement>): void => {
          const eventTarget = event?.target as HTMLSelectElement

          const nameMask = deviceNameMask(eventTarget?.value)
          settingsDispatch({
            type: DeviceActionType.DEVICE_NAME,
            payload: nameMask,
          })
        }}
      />
    </Flex>
  )
}
