import React, { ReactElement, forwardRef, useCallback } from 'react'
import AddIcon from 'images/add-icon.svg'
import { Button, ButtonVariant, Svg } from 'ui'
import { ThemeUIStyleObject } from 'theme-ui'
import {
  TrackingEventAction,
  TrackingEventCategory,
  TrackingEventName,
  useTrackingHelper,
} from 'utils/useTrackingHelper'
import VerticalDivider from 'ui/VerticalDivider'

interface AddButtonProps {
  onClick?: () => void
  dataTestId: string
  sx?: ThemeUIStyleObject
  disabled?: boolean
  variant?: ButtonVariant
  tabIndex?: number
  isDividerVisible?: boolean
  ariaLabel: string
}
const AddButton = forwardRef<HTMLButtonElement, AddButtonProps>(function AddButton(
  {
    onClick,
    dataTestId,
    sx = {},
    disabled = false,
    tabIndex,
    isDividerVisible,
    ariaLabel,
  }: AddButtonProps,
  ref,
): ReactElement {
  const { trackEvent } = useTrackingHelper()
  const handleClick = useCallback(() => {
    if (onClick) {
      trackEvent({
        category: TrackingEventCategory.Nav,
        action: TrackingEventAction.CustomRules,
        name: TrackingEventName.Add,
      })
      onClick()
    }
  }, [onClick, trackEvent])

  return (
    <>
      {isDividerVisible && <VerticalDivider sx={{ width: '1px', height: ['3.2rem', '4.8rem'] }} />}
      <Button
        ref={ref}
        variant="newPrimary"
        data-testid={dataTestId}
        ariaLabel={ariaLabel}
        sx={{
          borderRadius: '50%',
          height: ['3.2rem', '3.8rem'],
          minWidth: ['3.2rem', '3.8rem'],
          width: ['3.2rem', '3.8rem'],
          p: ['0.4rem', '0.8rem'],
          cursor: 'pointer',
          flexShrink: 0,
          ...sx,
        }}
        tabIndex={tabIndex}
        onClick={handleClick}
        disabled={disabled}
      >
        <Svg
          fill="black"
          svg={AddIcon}
          sx={{
            width: ['2.0rem', '2.4rem'],
            height: ['2.0rem', '2.4rem'],
            flexShrink: 0,
          }}
        />
      </Button>
    </>
  )
})

export default AddButton
